import styled from 'styled-components/macro';

/** Set up default heading elements */

export const H1 = styled.h1`
  margin: 0 0 1em;
  font-size: 5.2em;
  line-height: 1.2;
  font-weight: bold;
`;

export const H2 = styled.h2`
  margin: 0 0 0.8em;
  font-size: 3em;
  line-height: 1.3;
  font-weight: bold;
  color: ${props => props.theme.colors.navy};
`;

export const H3 = styled.h3`
  margin: 0 0 0.1em;
  font-size: 1.6em;
  line-height: 1.25;
  font-weight: bold;
  color: ${props => props.theme.colors.navy};
`;

export const H4 = styled.h4`
  margin: 0 0 0.1em;
  font-size: 1em;
  line-height: 1.1;
  font-weight: normal;
`;
