import styled from 'styled-components/macro';

/** Set up default text block elements */
export const Paragraph = styled.p`
  margin: 0 0 1em;
  font-size: 1.8em;
  line-height: 1.6;
  font-weight: normal;
`;

export const Intro = styled(Paragraph)`
  font-size: 2.2em;
  line-height: 1.4;
`;

export const Eyebrow = styled(Paragraph)`
  font-size: 1.4em;
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: 700;
  color: ${props => props.theme.colors.blue};
`;

export const Span = styled.span``;
