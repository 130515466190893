import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components/macro';
import { transparentize } from 'polished';
import contentCollectionTypes from '../../config/contentCollectionTypes.js';
import Container from '../Container';
import { Eyebrow } from '../Text';
import Form from '../Form';
import IconNav from '../IconNav';
import MarkdownParser from '../MarkdownParser';
import { stringToKebabCase } from '../../utilities';
import { setRoiValues } from '../../logic/actions';

const PageContentStyled = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 4rem;
  box-shadow: ${props =>
    `0px 6px 16px 4px ${transparentize(0.5, props.theme.colors.charcoal)}`};
  transform: translateY(-8.4em);
  padding: 6em;

  @media ${props => props.theme.breakpoints.medium} {
    width: 100%;
    padding: 6em;
    border-radius: 0;
  }
  @media ${props => props.theme.breakpoints.small} {
    padding: 3em;
  }
`;

const PageContent = props => {
  const {
    router,
    roiValues,
    updateRoiValue,
    // pageIcon ,
    pageIconCode,
  } = props;
  const { content, pageLabel, formElement, contentCollection } = props.fields;

  // TODO: Use `fetch` to get svg code rather than inputing raw svg code into contentful field
  //
  // const [pageIcons, setPageIcons] = useState([]);
  //
  // useEffect(() => {
  //   console.log({ props });
  //   if (!pageIcon) {
  //     return;
  //   }
  // });

  /**
   * Each page of the ROI Calculator has an associated icon. Here we collect them
   * all to display at the top of the page to highlight user progress
   */
  const pageIcons =
    props.pages &&
    props.pages.length &&
    props.pages
      .map(page => {
        const pageIcon = page.pageIconCode && page.pageIconCode;
        return pageIcon ? pageIcon : false;
      })
      .filter(icon => !!icon);

  /**
   * If a user loads the second or third page, check for a cookie or roiValues
   * redux item. If absent, send user to first page.
   */
  useEffect(() => {
    const isHomePage = router.location.pathname === '/';
    const isDeepDivePage = router.location.pathname === '/tell-us-more';
    const roiValuesSet = Object.values(roiValues).length;
    const urlParamValueEntries = [
      ...new URLSearchParams(router.location.search).entries(),
    ];

    if (
      !isHomePage &&
      !isDeepDivePage &&
      !roiValuesSet &&
      !urlParamValueEntries.length
    ) {
      /**
       * If we don't have redux state or url params, and we're not on the root
       * page, redirect to the root
       */
      props.push('/');
    } else if (urlParamValueEntries.length) {
      const newRoiValues = { ...roiValues };

      for (let [key, val] of urlParamValueEntries) {
        const newRoiValue = newRoiValues[`${key}`]
          ? newRoiValues[`${key}`]
          : {};
        if (val === 'true') {
          newRoiValue.val = true;
        } else if (val === 'false') {
          newRoiValue.val = false;
        } else {
          newRoiValue.val = val;
        }
        newRoiValues[`${key}`] = newRoiValue;
      }
      updateRoiValue(newRoiValues);
      // console.log({ newRoiValues });
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <PageContentStyled id={stringToKebabCase(pageLabel)}>
        {pageIconCode && (
          <IconNav icons={pageIcons} currentPage={router.location} />
        )}
        {/* {pageIcons && pageIcons.length && (
          <IconNav icons={pageIcons} currentPage={router.location} />
        )} */}
        {pageLabel && <Eyebrow>{pageLabel}</Eyebrow>}
        {content && <MarkdownParser>{content}</MarkdownParser>}
        {formElement && formElement && <Form formElement={formElement} />}
        {contentCollection &&
          contentCollection.length &&
          contentCollection.map(contentItem => {
            const contentType =
              contentItem &&
              contentItem.sys &&
              contentItem.sys.contentType &&
              contentItem.sys.contentType.sys &&
              contentItem.sys.contentType.sys.id;

            const ContentComponent = contentCollectionTypes[contentType];

            const formElement =
              contentItem.sys &&
              contentItem.sys.contentType &&
              contentItem.sys.contentType.sys &&
              contentItem.sys.contentType.sys.id === 'form' &&
              contentItem;

            return (
              ContentComponent && (
                <ContentComponent
                  {...contentItem}
                  key={contentItem.sys.id}
                  formElement={formElement ? formElement : null}
                />
              )
            );
          })}
      </PageContentStyled>
    </Container>
  );
};

PageContent.propTypes = {
  introCopy: PropTypes.shape({}),
  pageLabel: PropTypes.string,
};

const mapStateToProps = state => ({
  pages: state.content,
  router: state.router,
  roiValues: state.roiValues,
});

const mapDispatchToProps = dispatch => ({
  push: route => dispatch(push(route)),
  updateRoiValue: values => dispatch(setRoiValues(values)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PageContent);
