import styled, { keyframes, css } from 'styled-components/macro';
import { transparentize } from 'polished';

/** Animate the header sliding in when it becomes stuck */
const stick = keyframes`
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
`;

const stickAnimation = css`
  ${stick} 0.19s linear;
`;

/** Set the standard header styles, including when it's sticky */
export default styled.header`
  /** When it's above the first section, let the section bleed under it */
  position: ${props => {
    if (props.isStuck) {
      return 'fixed';
    }
    if (!props.isUnderFirstBlock) {
      return 'absolute';
    }
    return 'static';
  }};
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  background: ${props =>
    props.isStuck
      ? props.theme.colors.blue
      : transparentize(1, props.theme.colors.blue)};
  background: ${props =>
    props.isStuck
      ? `radial-gradient(circle, ${props.theme.colors.blue} 21%, ${props.theme.colors.blueShadow} 100%)`
      : transparentize(1, props.theme.colors.blue)};
  ${'' /* padding: ${props => (props.isStuck ? `2em 0` : `3em 0`)}; */}
  padding: 2em 0;
  /** Don't run the animation if it's under the first section */
  animation: ${props =>
    props.isStuck && !props.isUnderFirstBlock ? stickAnimation : null};

  @media ${props => props.theme.breakpoints.medium} {
    padding: 3em 0;
  }
`;
