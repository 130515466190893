import { createClient } from 'contentful';
import { simplifyContentfulStructure } from './index';

/**
 * Get all entries for the 'menu' content type, but only expect one result.
 * getEntries() will resolve the links of the menu's references, but
 * getEntry() doesn't for some reason.
 *
 * The actual redux-handling of errors is called when
 * getContentfulContent() is called (in actions.js for example)
 *
 * @param {String} space The space ID for contentful
 * @param {String} accessToken The access token (duh)
 * @returns {Promise} Resolves to an array of pages ordered by the menu
 */

const getContentfulContent = ({ space, accessToken }) =>
  new Promise((resolve, reject) => {
    /** Make sure we have what we need */
    if (!space || !accessToken) {
      const err = new Error('No space or accessToken provided for Contentful');
      // handlePromiseError(err);
      return reject(err);
    }

    const contentful = createClient({
      space,
      accessToken,
    });

    const content = [];

    /**
     * We could probably change this to only pull the menu with id 'mainMenu',
     * which is set when running the contentful setup script. But, if the user
     * manually created the main menu, they won't be able to set the ID and this
     * would fail
     */
    return (
      contentful
        .getEntries({
          content_type: 'menu',
          include: 10,
        })
        .then(
          entries => {
            /**
             * If there's more than one menu and the first one isn't the main
             * menu, you're gonna have a bad time
             */
            const items = entries.items[0].fields.navigationTree;
            /** Check if the navigation tree has any items in it */
            if (items.length) {
              items.forEach((item, index) => {
                content.push(simplifyContentfulStructure(item, index));
              });
            }

            return resolve(content);
          }
          /** If we needed a fallback API call we'd do that here */
          // err => {
          //   handlePromiseRejection(err);
          //   return reject(err);
          // }
        )
        // .catch(err => handlePromiseError(err));
        .catch(err =>
          /**
           * We want to pass this error along to the promise, not log it or do
           * anything with it
           */
          // handlePromiseError(err);
          reject(err)
        )
    );
  });

export default getContentfulContent;
