import React from 'react';
import styled from 'styled-components/macro';
import { transparentize, transitions } from 'polished';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Paragraph } from './Text';
import { H1, H2, H3, H4 } from './Heading';

const StyledLink = styled.a`
  color: ${props => props.theme.colors.blue};
  font-weight: 600;
  text-decoration: underline;
  font-size: 1.8em;

  ${Paragraph} & ,
  ${H1} & ,
  ${H2} & ,
  ${H3} & ,
  ${H4} &  {
    font-size: 1em;
  }
`;

const StyledLinkButton = styled(StyledLink)`
  background-color: ${props =>
    props.buttontheme === 'primary'
      ? props.theme.colors.blue
      : props.theme.colors.white};
  color: ${props =>
    props.buttontheme === 'primary'
      ? props.theme.colors.white
      : props.theme.colors.blue};
  border: ${props =>
    props.buttontheme === 'primary'
      ? `2px solid ${props.theme.colors.blue}`
      : `2px solid ${props.theme.colors.white}`};
  text-decoration: none;
  font-size: 1.6rem;
  border-radius: 1rem;
  font-weight: 600;
  padding: 1em 2.4rem;
  padding: 1.8rem 5.8rem;
  display: inline-block;
  ${props =>
    transitions(
      ['background-color', 'color', 'border-color'],
      props.theme.transitions.default
    )};

  &:hover {
    background-color: ${props => transparentize(1, props.theme.colors.white)};
    color: ${props =>
      props.buttontheme === 'primary'
        ? props.theme.colors.blue
        : props.theme.colors.white};
    border: ${props =>
      props.buttontheme === 'primary'
        ? `2px solid ${props.theme.colors.blue}`
        : `2px solid ${props.theme.colors.white}`};
  }
`;

// TODO: resuse the styles above instead of recreating them here.
const StyledHashLink = styled(HashLink)`
  background-color: ${props =>
    props.buttontheme === 'primary'
      ? props.theme.colors.blue
      : props.theme.colors.white};
  color: ${props =>
    props.buttontheme === 'primary'
      ? props.theme.colors.white
      : props.theme.colors.blue};
  border: ${props =>
    props.buttontheme === 'primary'
      ? `2px solid ${props.theme.colors.blue}`
      : `2px solid ${props.theme.colors.white}`};
  text-decoration: none;
  font-size: 1.6rem;
  border-radius: 1rem;
  font-weight: 600;
  padding: 1em 2.4rem;
  padding: 1.8rem 5.8rem;
  display: inline-block;
  ${props =>
    transitions(
      ['background-color', 'color', 'border-color'],
      props.theme.transitions.default
    )};

  &:hover {
    background-color: ${props => transparentize(1, props.theme.colors.white)};
    color: ${props =>
      props.buttontheme === 'primary'
        ? props.theme.colors.blue
        : props.theme.colors.white};
    border: ${props =>
      props.buttontheme === 'primary'
        ? `2px solid ${props.theme.colors.blue}`
        : `2px solid ${props.theme.colors.white}`};
  }
`;

export const CtaButton = props => {
  const { href, buttonTheme, children, className } = props;

  /** Test for an anchor tag by listening for a leading # */
  const isAnchor = /^#/.test(href);

  const LinkComponent = isAnchor ? StyledHashLink : StyledLinkButton;

  return (
    <LinkComponent
      href={href}
      to={href}
      smooth
      buttontheme={buttonTheme.toLowerCase()}
      className={className}
    >
      {children}
    </LinkComponent>
  );
};

export default props => {
  /**
   * Determine if a link is external by constructing a URL object from the prop,
   * then comparing the hostname of the URL with the window's hostname. If the
   * `href` prop is a relative link like '/test', the window.location will be
   * used as a base. If it's an absolute URL like 'https://google.com', the base
   * will be ignored
   */
  const isExternal =
    new URL(props.href, window.location).hostname !== window.location.hostname;

  const attrs = isExternal
    ? /** Add extra attributes for external links */
      {
        href: props.href,
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : /** Internal Link components need the "to" prop instead of "href" */
      {
        to: props.href,
      };

  return (
    <StyledLink
      {...props}
      {...attrs}
      /** If the link is internal, use a react-router Link */
      as={isExternal ? undefined : Link}
    />
  );
};
