import {
  parseMarkdownFrontmatter,
  parseMarkdownCollection,
} from './parseMarkdown';
import { handleFetch, stripSlashes } from '../../../utilities';

/**
 * Read local markdown files, starting with a menu.md file to get the page items
 * and the order in which they should be displayed. Matches Contenful's content
 * structure.
 *
 * @returns {Promise} Resolves to an array of page items
 */
export default async () => {
  try {
    /** The only required file for this to work */
    const menuPath = `${process.env.PUBLIC_URL}/content/menu.md`;
    const menu = await (await handleFetch(menuPath, {
      headers: {
        /**
         * If we don't add this header the root index.html will be returned if
         * menu.md doesn't exist
         */
        Accept: 'text/plain',
      },
    })).text();

    if (!menu) {
      throw new Error(
        'A menu.md file must be included in the content directory that includes a page ID corresponding to a Markdown file. e.g.:\npage1\npage2'
      );
    }

    /** Split each newline into a menu path, then filter out the invalid ones */
    const menuItems = menu.split(/\n/).filter(Boolean);

    const content = menuItems.map(async (item, index) => {
      try {
        /** Replace the .md extension if it exists as a safety check */
        const itemName = item.replace(/\.md?/i, '');
        /** Assumes all content is in ./public/content/[filename].md */
        const contentPath = `${process.env.PUBLIC_URL}/content/${itemName}.md`;
        /** Fetch the menu item */
        const rawContent = await (await handleFetch(contentPath, {
          headers: {
            /**
             * If we don't add this header the root index.html will be returned
             * if the file doesn't exist
             */
            Accept: 'text/plain',
          },
        })).text();

        const frontmatter = parseMarkdownFrontmatter(rawContent);

        if (!frontmatter || !Object.entries(frontmatter).length) {
          /** Ugly line breaks, but template literals include whitespace */
          console.warn(`Missing frontmatter in ${itemName}.md. By default the section will use the name of the Markdown file. Include it like this:
---
menuTitle: Menu Title
metaTitle: Meta Title
metaDescription: Meta Description
path: page1
---`);
        }

        const collection = parseMarkdownCollection(rawContent);

        /** Format the collection that was returned to match Contentful */
        let collectionFormatted = [];
        if (collection && collection.length) {
          collectionFormatted = collection.map(block => {
            return {
              contentType: block.contentType,
              id: block.id,
              fields: {
                ...block.options,
                body: block.content,
              },
            };
          });
        }

        /**
         * If it's the root section, explicitly set it as the root. Otherwise do
         * a safety check for extra leading or trailing slashes on the path, and
         * fallback to the item's ID if that doesn't exist
         */
        const path =
          index === 0
            ? '/'
            : frontmatter.path
            ? `/${stripSlashes(frontmatter.path)}`
            : `/${item}`;

        /** The content object is modeled to match Contentful's */
        return Promise.resolve({
          ...frontmatter,
          contentType: 'page',
          id: item,
          /** Add fallbacks for some standard frontmatter */
          menuTitle: frontmatter.menuTitle || item,
          metaTitle: frontmatter.metaTitle || item,
          metaDescription: frontmatter.metaDescription || null,
          path,
          collection: collectionFormatted,
        });
      } catch (err) {
        console.error(err);
        /**
         * We don't want to break the whole thing if one piece of content fails
         * to load or be formatted
         */
        return Promise.resolve(false);
      }
    });

    /** Wait for all the content to load then filter out the failed ones */
    const resolvedContent = await Promise.all(content).then(c =>
      c.filter(Boolean)
    );

    return resolvedContent;
  } catch (err) {
    console.error(err);
  }
};
