import RoiResultsHero from '../components/RoiResultsHero';
import InputResultCollection from '../components/InputResultCollection';
import Form from '../components/Form';
import ShareLink from '../components/ShareLink';
import CTA from '../components/CTA';
import TextBlock from '../components/TextBlock';
// import DeepDiveQuestionCollection from '../components/DeepDiveQuestionCollection';

/**
 * Define default blocks available for sections, matching the content type's
 * machine name and the actual component name. If you have a content type
 * defined in the content (remote or local), it must have a block associated
 * with it or it won't display.
 *
 * For example, if you add a custom callout content type, you'll need a Callout
 * component, and it needs to be defined here as { callout: Callout }
 */
export default {
  roiResults: RoiResultsHero,
  inputResult: InputResultCollection,
  form: Form,
  shareLink: ShareLink,
  cta: CTA,
  textBlock: TextBlock,
  // deepDiveQuestionGroup: DeepDiveQuestionCollection,
};
