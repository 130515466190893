import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setRoiValues } from '../logic/actions';
import styled, { css } from 'styled-components/macro';
import { transparentize } from 'polished';
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@material-ui/core';
import { stringToCamelCase } from '../utilities';

const FormLabelStyled = styled(FormLabel)``;

const FormInner = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  @media ${props => props.theme.breakpoints.medium} {
    justify-content: flex-start;
  }
`;

const FormControlStyled = styled(FormControl)`
  width: 100%;

  ${FormLabelStyled} {
    font-size: 1.5em;
    line-height: 1.3;
    font-weight: 500;
    color: ${props => props.theme.colors.navy};
    &.Mui-focused {
      color: ${props => props.theme.colors.navy};
    }
  }

  @media ${props => props.theme.breakpoints.medium} {
    ${props =>
      props.addLeftMargin
        ? css`
            margin-left: 4.6em !important;
          `
        : ''};
  }
`;

const RadioGroupStyled = styled(RadioGroup)`
  flex-direction: row;
  justify-content: flex-end;
  width: 14em;

  @media ${props => props.theme.breakpoints.medium} {
    justify-content: flex-start;
    margin-left: 0.6em;
  }
`;

const FormControlLabelStyled = styled(FormControlLabel)`
  font-size: 1.5em;
  font-weight: 500;
  margin-bottom: 0;
  ${'' /* width: 100%; */}

  .MuiFormControlLabel-label {
    font-size: 1em;
  }
`;

const RadioStyled = styled(Radio)`
  &.MuiRadio-colorSecondary.Mui-checked {
    color: ${props => props.theme.colors.blue};
  }
  &.MuiIconButton-colorSecondary {
    color: ${props => props.theme.colors.blueShadow};
  }
  &.MuiRadio-colorSecondary.Mui-checked,
  &.MuiIconButton-colorSecondary {
    &:hover {
      background-color: ${props =>
        transparentize(0.8, props.theme.colors.blue)};
    }
  }
`;

const InputRadio = props => {
  const {
    id,
    questionLabel,
    options,
    handleSelectionState,
    hoursSavedMultiplier,
    hoursSavedPerYear,
    hoursSavedPerYearIfAutomated,
    updateRoiValue,
    roiValues,
    addLeftMargin = false,
  } = props;
  const [selection, setSelection] = useState(null);
  const groupReduxName = stringToCamelCase(id);
  const multiplyPerEmployee =
    hoursSavedMultiplier && hoursSavedMultiplier === 'Per Employee Per Year';

  const handleChange = e => {
    setSelection(e.target.value);

    /**
     * If recieved a handleSelectionState callback, call that. Otherwise set
     * the redux state
     */
    if (handleSelectionState) {
      handleSelectionState(e);
    } else {
      const newRoiValues = { ...roiValues };
      newRoiValues[`${groupReduxName}`] = {
        val: e.target.value,
        multiplyPerEmployee,
        hoursSavedPerYear,
        hoursSavedPerYearIfAutomated,
      };

      updateRoiValue(newRoiValues);
    }
  };

  return (
    <FormControlStyled component="fieldset" addLeftMargin={addLeftMargin}>
      <FormInner>
        <FormLabelStyled component="legend">{questionLabel}</FormLabelStyled>
        <RadioGroupStyled onChange={handleChange} row>
          {options.map(option => (
            <FormControlLabelStyled
              key={`${stringToCamelCase(`${questionLabel} ${option.value}`)}`}
              label={option.label}
              value={option.value}
              control={<RadioStyled />}
              labelPlacement="end"
            />
          ))}
        </RadioGroupStyled>
      </FormInner>
    </FormControlStyled>
  );
};

InputRadio.propTypes = {
  questionLabel: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

const mapStateToProps = state => ({
  roiValues: state.roiValues,
  router: state.router,
});

InputRadio.defaultProps = {
  questionLabel: '',
  options: [],
};

const mapDispatchToProps = dispatch => ({
  updateRoiValue: values => dispatch(setRoiValues(values)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputRadio);
