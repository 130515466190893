import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import initialState from './initialState';

/**
 * All of the reducer functions use these base parameters:
 * @param {Object} [state=initialState] Current store state
 * @param {String} action.type          The action identifier
 */

/**
 * Handles the content loading
 * @param {Array} action.payload Contains an object for each piece of content
 */
const content = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_CONTENT_SUCCESS':
      return action.payload;
    default:
      return state;
  }
};

const menu = (state = initialState, action) => {
  switch (action.type) {
    case 'MENU_ITEMS_SET':
      return action.payload;
    default:
      return state;
  }
};

/**
 * Handles the placement of the header and it's sticky state
 * @param {Boolean} action.payload The toggle for each key
 */
const header = (state = initialState, action) => {
  switch (action.type) {
    case 'HEADER_IS_STUCK_SET':
      return {
        ...state,
        isStuck: action.payload,
      };
    case 'HEADER_IS_UNDER_FIRST_SECTION_SET':
      return {
        ...state,
        isUnderFirstBlock: action.payload,
      };
    default:
      return state;
  }
};

/**
 * Handles errors we want the user to see
 * @param {String} action.error The error message to display
 */
const globalError = (state = initialState, action) => {
  switch (action.type) {
    case 'GLOBAL_ERROR_SHOW':
      return action.error;
    case 'GLOBAL_ERROR_RESET':
      return '';
    default:
      return state;
  }
};

const sectionRefs = (state = initialState, action) => {
  switch (action.type) {
    case 'SECTION_REFS_ADD':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const isSectionWaypointEnabled = (state = initialState, action) => {
  switch (action.type) {
    case 'SECTION_WAYPOINT_ENABLED_SET':
      return action.payload;
    default:
      return state;
  }
};

const isGateReleased = (state = initialState, action) => {
  switch (action.type) {
    case 'GATE_RELEASE_SET':
      return action.payload;
    default:
      return state;
  }
};

/**
 * Updates the values used to calculate the ROI totals
 */
const roiValues = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT_ROI_VALUES':
      return {
        ...state,
        ...action.payload,
      };
    case 'ROI_VALUES_SET':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

/** Put it all together */
export default history =>
  combineReducers({
    router: connectRouter(history),
    content,
    menu,
    header,
    globalError,
    sectionRefs,
    isSectionWaypointEnabled,
    isGateReleased,
    roiValues,
  });
