import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setRoiValues } from '../logic/actions';
import styled from 'styled-components/macro';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Input from './InputText';
import { stringToCamelCase } from '../utilities';

const FormGroupStyled = styled(FormGroup)`
  flex-flow: row !important;
  align-items: center;

  .MuiSwitch-colorPrimary.Mui-checked {
    color: ${props => props.theme.colors.blue};
  }

  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: ${props => props.theme.colors.blueShadow};
  }

  .MuiFormControl-marginDense {
    margin: 0;
  }

  .MuiTextField-root {
    width: 50%;
  }

  @media ${props => props.theme.breakpoints.medium} {
    flex-flow: column !important;
    width: 100%;
    align-items: flex-end;
    margin-left: 4.6em;

    .MuiFormControl-root {
      width: 100%;
    }
  }
`;

const GridStyled = styled(Grid)`
  &.MuiGrid-spacing-xs-1 {
    margin: 0 1.2em 0 0;
    width: ${props => (props.fullWidth ? '100%' : '60%')};

    @media ${props => props.theme.breakpoints.medium} {
      display: flex;
      justify-content: flex-end;
      margin: 0;
      width: 100%;
    }
    @media ${props => props.theme.breakpoints.small} {
      justify-content: flex-start;
    }
  }
`;

const GridItem = styled(Grid)`
  font-weight: 500;
`;

const GridItemLabel = styled(GridItem)`
  color: ${props =>
    props.highlightColor &&
    props.theme.colors[props.highlightColor] &&
    props.isHighlighted
      ? props.theme.colors[props.highlightColor]
      : props.theme.colors.charcoal};
  font-weight: ${props => (props.isHighlighted ? '600' : '500')};
`;

const FollowUpInput = styled(Input)`
  @media ${props => props.theme.breakpoints.small} {
    width: 100%;
  }
`;

const SwitchItem = props => {
  const {
    id,
    // inputGroupName,
    followupInput,
    hoursSavedPerYear,
    hoursSavedPerYearIfAutomated,
    hoursSavedMultiplier,
    roiValues,
    updateRoiValue,
    router,
  } = props;

  const groupReduxName = stringToCamelCase(id);
  const multiplyPerEmployee =
    hoursSavedMultiplier && hoursSavedMultiplier === 'Per Employee Per Year';

  const [automated, setAutomated] = useState(false);

  const followupInputType =
    followupInput &&
    followupInput.sys &&
    followupInput.sys.contentType &&
    followupInput.sys.contentType.sys &&
    followupInput.sys.contentType.sys.id;

  const handleChange = () => event => {
    setAutomated(event.target.checked);
  };

  useEffect(() => {
    if (typeof roiValues[groupReduxName] === 'undefined') {
      const newRoiValues = { ...roiValues };
      newRoiValues[groupReduxName] = {
        val: false,
        multiplyPerEmployee,
        hoursSavedPerYear,
        hoursSavedPerYearIfAutomated,
      };
    }
    // eslint-disable-next-line
  }, []);

  /** Initialize this item in redux state using url param if available */
  useEffect(() => {
    const newRoiValues = { ...roiValues };
    const urlParams = new URLSearchParams(router.location.search);
    let paramVal = false;

    if (urlParams) {
      const param = urlParams.get(groupReduxName);

      /** For the switch component, we are only concerned with boolean values */
      paramVal =
        param && param === 'true'
          ? true
          : param && param === 'false'
          ? false
          : false;
    }

    newRoiValues[groupReduxName] = {
      val: automated,
      multiplyPerEmployee,
      hoursSavedPerYear,
      hoursSavedPerYearIfAutomated,
    };
    /** Set local state and update redux state */
    setAutomated(paramVal);
    updateRoiValue(newRoiValues);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const newRoiValues = { ...roiValues };
    newRoiValues[groupReduxName] = {
      val: automated,
      multiplyPerEmployee,
      hoursSavedPerYear,
      hoursSavedPerYearIfAutomated,
    };
    updateRoiValue(newRoiValues);
    // eslint-disable-next-line
  }, [
    groupReduxName,
    hoursSavedPerYear,
    multiplyPerEmployee,
    automated,
    updateRoiValue,
  ]);

  return (
    <FormGroupStyled>
      <GridStyled
        component="label"
        container
        alignItems="center"
        spacing={1}
        fullWidth={!automated || !followupInputType}
      >
        <GridItemLabel isHighlighted={!automated} highlightColor="navy" item>
          Manual
        </GridItemLabel>
        <GridItem item>
          <Switch
            checked={automated}
            onChange={handleChange()}
            value={id}
            color="primary"
          />
        </GridItem>
        <GridItemLabel isHighlighted={automated} highlightColor="blue" item>
          Automated
        </GridItemLabel>
      </GridStyled>
      {followupInputType && followupInputType === 'textInput' && automated && (
        <FollowUpInput
          type="text"
          label={followupInput.fields.fieldInput}
          id={followupInput.fields.fieldId}
          inputGroupReduxName={groupReduxName}
          margin="dense"
        />
      )}
    </FormGroupStyled>
  );
};

const mapStateToProps = state => ({
  roiValues: state.roiValues,
  router: state.router,
});

const mapDispatchToProps = dispatch => ({
  updateRoiValue: values => dispatch(setRoiValues(values)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwitchItem);
