import { INPUT_TYPES } from '../../config/forms';
import { stringToCamelCase } from '../../utilities';

/**
 * Receives the content from all pages and builds the inital roiValues state
 * object to pass to redux
 */

export default (content, router) => {
  const intialRoiValues = {};
  const urlParams = new URLSearchParams(router.location.search);

  /** Map through the pages and try to find the form elements */
  const formElements =
    content &&
    content.length &&
    content
      .map(page => {
        const { collection } = page;
        const pageContent =
          collection &&
          collection.filter(
            collectionItem => collectionItem.contentType === 'pageContent'
          );
        const contentCollection =
          pageContent &&
          pageContent.length &&
          pageContent[0].fields &&
          pageContent[0].fields.contentCollection;
        const formElement =
          contentCollection &&
          contentCollection.length &&
          contentCollection.filter(
            collectionItem =>
              collectionItem.sys &&
              collectionItem.sys &&
              collectionItem.sys.contentType &&
              collectionItem.sys.contentType.sys &&
              collectionItem.sys.contentType.sys.id === 'form'
          );
        /** This assumes there is only one form on the page */
        return formElement && formElement.length ? formElement[0] : null;
      })
      /** Filter out the `null` items, returning only an array of forms */
      .filter(element => element);

  formElements.forEach(form => {
    // const inputs = [];
    const inputGroups = form.fields && form.fields.inputGroups;
    inputGroups.forEach(group => {
      const inputGroupType =
        group.sys &&
        group.sys.contentType &&
        group.sys.contentType.sys &&
        group.sys.contentType.sys.id;

      /** Basic Input Group does not have nested inputs */
      if (inputGroupType === INPUT_TYPES.basic) {
        const { inputs } = group.fields;
        inputs.forEach(input => {
          const inputReduxName = stringToCamelCase(input.fields.fieldId);
          let inputValue = '';

          /** Check for url params to set as initial values */
          if (urlParams) {
            const paramInputVal = urlParams.get(inputReduxName);
            inputValue = paramInputVal ? paramInputVal : '';
          }

          intialRoiValues[`${inputReduxName}`] = {
            val: inputValue ? inputValue : '',
            fieldId: input.fields.fieldId,
          };
        });
      }

      /**
       * Standard Input Groups record automation values and have nested
       * questions / inputs
       */
      if (inputGroupType === INPUT_TYPES.group) {
        const {
          inputId,
          hoursSavedPerYear,
          hoursSavedMultiplier,
          hoursSavedPerYearIfAutomated,
        } = group.fields;
        const inputReduxName = stringToCamelCase(inputId);
        let inputValue = false;

        /** Check for url params to set as initial values */
        if (urlParams) {
          const paramAutomatedVal = urlParams.get(inputReduxName);

          if (typeof paramAutomatedVal !== 'undefined') {
            if (paramAutomatedVal === 'true') {
              inputValue = true;
            } else if (paramAutomatedVal === 'false') {
              inputValue = false;
            } else {
              inputValue = paramAutomatedVal;
            }
          }
        }

        intialRoiValues[`${inputReduxName}`] = {
          val: inputValue,
          multiplyPerEmployee: hoursSavedMultiplier === 'Per Employee Per Year',
          hoursSavedPerYear,
          hoursSavedPerYearIfAutomated,
          fieldId: inputId,
        };
      }
    });
  });

  return intialRoiValues;
};
