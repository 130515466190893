import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import MarkdownParser from './MarkdownParser';
import { Paragraph } from './Text';

const CtaBlock = styled.div`
  padding: 3em 6.6em;

  @media ${props => props.theme.breakpoints.medium} {
    padding: 2em;
  }
`;

const CtaSection = styled.div``;

const CTA = props => {
  const { linkUrl, linkText, leadInContent } = props.fields;

  const ctaLink = `[${linkText}](${linkUrl})`;

  return (
    <CtaBlock>
      <CtaSection>
        {leadInContent && <Paragraph>{leadInContent}</Paragraph>}
      </CtaSection>
      <CtaSection>
        {ctaLink && <MarkdownParser>{ctaLink}</MarkdownParser>}
      </CtaSection>
    </CtaBlock>
  );
};

CTA.propTypes = {
  fields: PropTypes.shape({
    leadInContent: PropTypes.string,
    linkUrl: PropTypes.string,
    linkText: PropTypes.string,
    // openInNewTab: PropTypes.bool,
  }),
};

CTA.defaultProps = {
  fields: {
    leadInContent: '',
    linkUrl: '',
    linkText: '',
    // openInNewTab: true,
  },
};

export default CTA;
