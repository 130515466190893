import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import { setSectionWaypointEnabled } from '../logic/actions';
/** TODO: move ispaged to redux state */
import config from '../config';

/**
 * Listen for URL path changes and scroll to the top of the section that matches
 * that path. This will prevent auto-scrolling if the URL path was changed
 * because of user scrolling or if there's not a section that matches the URL
 * path. It'll also disable section waypoints that would otherwise trigger other
 * URL changes and cause it to become out of sync with the visible section
 *
 * Derived from https://reacttraining.com/react-router/web/guides/scroll-restoration/scroll-to-top
 */
const ScrollToTop = ({
  location,
  sectionRefs,
  isSectionWaypointEnabled,
  setSectionWaypointEnabled,
  replace,
}) => {
  useEffect(() => {
    /**
     * If the lightpaper is paged, the ref measurement won't work because the
     * routes won't exist in the DOM. Also, we just want to go to the top of the
     * new section, which should always be the top of the window
     */
    if (config.isPaged) {
      window.scrollTo(0, 0);
    } else if (
      /**
       * ~~If there's no section refs that likely means it's page load~~
       *
       * Apparently this does run on page load, so we have to add a hack below
       */
      Object.keys(sectionRefs).length &&
      /**
       * Location changes caused by a section's waypoint add a special identifier
       * to the location object (bad practice?) so that scrolling navigation
       * changes don't also trigger a forced scrolling to the top of that section
       */
      !location.isScrolled
    ) {
      /** For restoring later */
      const path = location.pathname;
      const sectionLocation = sectionRefs[location.pathname];
      /** By this point, if the path doesn't match a ref, something's wrong */
      if (!sectionLocation) {
        console.error(
          `Can't find a ref for section ${location.pathname}. Make sure all navigation links are mapped to a valid content path.`
        );
        return false;
      }

      /** Prevent section waypoints from firing their own navigation events */
      setSectionWaypointEnabled(false);

      /**
       * Get the location of the top of the new section. For paged lightpapers
       * this should be 0
       */
      const measurement = sectionLocation.offsetTop;

      window.scrollTo(0, measurement);

      /**
       * HACK: window scroll doesn't wait until completion before running the
       * re-enablement of section waypoints. If there's a way to check for
       * scroll completion we can reliably enable the waypoint at the right time
       */
      setTimeout(() => {
        setSectionWaypointEnabled(true);
        /**
         * HACK: On page loads with a lot of content to load from Contentful,
         * the delay in restoring the scroll position and mounting the
         * components causes the above code to run and somehow remove the
         * current location from the router. So just make sure it gets reapplied
         */
        replace(path);
        return false;
      }, 100);
    }
  }, [
    location.isScrolled,
    location.pathname,
    replace,
    sectionRefs,
    setSectionWaypointEnabled,
  ]);

  return null;
};

const mapStateToProps = state => ({
  sectionRefs: state.sectionRefs,
  isSectionWaypointEnabled: state.isSectionWaypointEnabled,
});

const mapDispatchToProps = {
  setSectionWaypointEnabled,
  replace,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ScrollToTop)
);
