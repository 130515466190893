import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { H3 } from './Heading';
import { Paragraph } from './Text';
import Counter from './Counter';
import config from '../config';

const ResultsBlock = styled.div`
  margin: 5.4em auto;
  width: 88%;

  @media ${props => props.theme.breakpoints.medium} {
    width: 92%;
  }
`;

const ResultsContainer = styled.div`
  display: flex;
  flex-flow: column;
  padding: 6em 4em;
  margin: 0 auto;
  background: ${props => props.theme.colors.navyShadow};
  background: ${props =>
    `radial-gradient(circle, ${props.theme.colors.navy} 0%, ${props.theme.colors.navyShadow} 100%)`};
  border-radius: 2rem;

  @media ${props => props.theme.breakpoints.medium} {
    padding: 6em 2em;
  }
  ${'' /* @media ${props => props.theme.breakpoints.small} {
    flex-flow: column;
  } */}
`;

const ResultSection = styled.div`
  ${'' /* width: 50%; */}
  width: 100%;
  margin: 0 auto;
  padding: 0 1em;
  text-align: center;
  margin-bottom: 5.2em;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${props => props.theme.breakpoints.medium} {
    ${'' /* width: 100%; */}
    margin-bottom: 6em;
  }
`;

const CounterWrapper = styled.div`
  display: flex;
  font-size: 7rem;
  margin: 0;
  line-height: 1.2;
  font-weight: bold;
  justify-content: center;
  color: ${props => props.theme.colors.periwinkle};
  line-height: 0.8;
  margin-bottom: 0.3em;

  @media ${props => props.theme.breakpoints.medium} {
    font-size: 5rem;
    display: flex;
    flex-flow: column;
  }
`;

const ResultDescription = styled(H3)`
  color: ${props => props.theme.colors.white};
`;

const ResultsDisclaimer = styled(Paragraph)`
  font-size: 1em;
  text-align: right;
  margin: 1em 0;
`;

const ResultHero = props => {
  const {
    moneyResultDescription,
    timeResultDescription,
    disclaimerText,
  } = props.fields;

  const { roiValues } = props;

  const { employeeCountReduxName } = config;

  const roiKeys = Object.keys(roiValues);
  const employeeCount = roiValues[employeeCountReduxName];
  const tasksWithSavings =
    roiKeys &&
    roiKeys.length &&
    roiKeys
      .map(key => {
        const val = roiValues[key];
        return {
          ...val,
          id: key,
        };
      })
      /**
       * Filter down to the items that should be used to calculate savings.
       * These are items that are either manual (val.val === 'no'), or if the
       * item has a `hoursSavedPerYearIfAutomated` value.
       */
      .filter(val => {
        const hasSavings =
          typeof val.val !== 'undefined'
            ? !val.val || val === 'no' || !!val.hoursSavedPerYearIfAutomated
            : false;

        return hasSavings;
      });

  /** Go through each manual entry to calculate by either by employee or one-time cost */
  const timeSavedResult =
    tasksWithSavings &&
    tasksWithSavings.reduce((acc, curr) => {
      const multiplier =
        curr.multiplyPerEmployee && employeeCount && employeeCount.val
          ? parseFloat(employeeCount.val)
          : 1;

      const currentCalc =
        curr.val && !!curr.hoursSavedPerYearIfAutomated
          ? parseFloat(curr.hoursSavedPerYearIfAutomated) * multiplier
          : parseFloat(curr.hoursSavedPerYear) * multiplier;

      return !curr.hoursSavedPerYear ? acc : acc + currentCalc;
    }, 0);

  /** Calculate money saved by using employeeCount */
  const moneySavedResultFloor =
    employeeCount &&
    employeeCount.val &&
    (() => {
      const COST_PER_EE_FLOOR = 50;
      return employeeCount.val * COST_PER_EE_FLOOR;
    })();
  const moneySavedResultCeil =
    employeeCount &&
    employeeCount.val &&
    (() => {
      const COST_PER_EE_CEIL = 300;
      return employeeCount.val * COST_PER_EE_CEIL;
    })();

  return (
    <ResultsBlock>
      <ResultsContainer>
        {typeof timeSavedResult !== 'undefined' && timeResultDescription && (
          <ResultSection>
            <CounterWrapper>
              <Counter start="0" end={`${timeSavedResult}`} />
            </CounterWrapper>
            <ResultDescription>{timeResultDescription}</ResultDescription>
          </ResultSection>
        )}
        {moneySavedResultFloor && moneyResultDescription && (
          <ResultSection>
            <CounterWrapper>
              <Counter start="0" end={`${moneySavedResultFloor}`} prefix="$" />
              {' - '}
              <Counter start="0" end={`${moneySavedResultCeil}`} prefix="$" />
            </CounterWrapper>
            <ResultDescription>{moneyResultDescription}</ResultDescription>
          </ResultSection>
        )}
      </ResultsContainer>
      {disclaimerText && (
        <ResultsDisclaimer>{disclaimerText}</ResultsDisclaimer>
      )}
    </ResultsBlock>
  );
};

const mapStateToProps = state => {
  return {
    roiValues: state.roiValues,
  };
};

export default connect(mapStateToProps)(ResultHero);
