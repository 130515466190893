import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setRoiValues } from '../logic/actions';
import styled from 'styled-components/macro';
import { transparentize } from 'polished';
import { stringToCamelCase } from '../utilities';
import InputRadio from './InputRadio';
import InputText from './InputText';

const DeepDiveQuestionStyled = styled.div`
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  flex-wrap: wrap;
  align-items: center;
  ${'' /* padding: 1em 3em; */}
  margin: 0.6rem 0 0.6rem auto;
  padding: 1.8em 2em;
  border-radius: 2rem;
  min-height: 8.2rem;
  width: calc(100% - 3em);
  background-color: ${props =>
    props.isOpen
      ? transparentize(0.9, props.theme.colors.blue)
      : transparentize(0.6, props.theme.colors.cloud)};
  transition: ${props => `background-color ${props.theme.transitions.default}`};
`;

const QuestionSection = styled.div`
  width: 100%;
  margin: 1em 0;
`;

const DeepDiveQuestion = props => {
  const { isOpen, updateRoiValue, roiValues } = props;
  const {
    questionId,
    questionLabel,
    questionType,
    followupQuestions,
  } = props.fields;
  const [state, setState] = useState({ hasSelection: false });
  const inputReduxName = stringToCamelCase(questionId);

  const handleSelectionState = e => {
    const newRoiValues = { ...roiValues };

    const hasSelection =
      e &&
      e.target &&
      e.target.value &&
      e.target.value.length &&
      e.target.value.toLowerCase() !== 'no'
        ? true
        : false;

    newRoiValues[inputReduxName] = { val: hasSelection };

    setState({ ...state, hasSelection });
    updateRoiValue(newRoiValues);
  };

  return (
    <DeepDiveQuestionStyled isOpen={isOpen}>
      <QuestionSection>
        {questionType && stringToCamelCase(questionType) === 'yesNo' ? (
          <InputRadio
            questionLabel={questionLabel}
            options={[
              { label: 'Yes', value: 'yes' },
              { label: 'No', value: 'no' },
            ]}
            handleSelectionState={handleSelectionState}
          />
        ) : questionType && stringToCamelCase(questionType) === 'text' ? (
          <InputText
            label={questionLabel}
            id={questionId}
            type="text"
            handleSelectionState={handleSelectionState}
            useBaseLayout
            useFullWidth
          />
        ) : null}
      </QuestionSection>
      {/** Only show followup questions if main question has a value */
      followupQuestions &&
        state.hasSelection &&
        followupQuestions.length &&
        followupQuestions.map(question => {
          const { id, contentType } = question.sys;
          const { fieldId, fieldInput } = question.fields;
          const contentTypeVal =
            contentType && contentType.sys && contentType.sys.id;

          return contentTypeVal && contentTypeVal === 'textInput' ? (
            <QuestionSection key={id}>
              <InputText
                type="text"
                label={fieldInput}
                id={fieldId}
                useBaseLayout
                isFollowUp
              />
            </QuestionSection>
          ) : // <p>Unknown content type: {contentTypeVal}</p>
          null;
        })}
    </DeepDiveQuestionStyled>
  );
};

DeepDiveQuestion.propTypes = {
  isOpen: PropTypes.bool,
  fields: PropTypes.shape({
    questionId: PropTypes.string,
    questionLabel: PropTypes.string,
    questionType: PropTypes.string,
    followupQuestions: PropTypes.array,
  }),
};

const mapStateToProps = state => ({
  roiValues: state.roiValues,
});

const mapDispatchToProps = dispatch => ({
  updateRoiValue: values => dispatch(setRoiValues(values)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeepDiveQuestion);
