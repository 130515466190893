import React from 'react';
import { connect } from 'react-redux';
import { setRoiValues } from '../logic/actions';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { stringToCamelCase } from '../utilities';

const InputLabelStyled = styled(InputLabel)``;

const FormControlStyled = styled(FormControl)`
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  font-size: 1.5rem;
  ${'' /* border-radius: 1em; */}
  padding: 2.4rem 1.8rem 1.4rem;
  width: 100%;
  font-size: 1.5rem;
  line-height: 1;

  color: ${props => props.theme.colors.cement};

  /** Label styles when focused */
  &.Mui-focused {
    line-height: 1;
    color: ${props => props.theme.colors.blue};
  }

  /** Label styles when filled */
  &.MuiFormLabel-filled {
    line-height: 1;
    color: ${props => props.theme.colors.navy};
  }

  /** The carat in the dropdown */
  .MuiSelect-icon {
    color: ${props => props.theme.colors.blue};
    width: 1.6em;
    height: 1.6em;
    top: calc(50% - 0.8em);
  }

  ${InputLabelStyled} {
    font-size: 1.5rem;
    line-height: 0.6;
    color: ${props => props.theme.colors.cement};

    /** Label styles when focused */
    &.Mui-focused {
      line-height: 1;
      color: ${props => props.theme.colors.blue};
    }

    /** Label styles when filled */
    &.MuiFormLabel-filled {
      line-height: 1;
      color: ${props => props.theme.colors.navy};
    }
  }

  .MuiOutlinedInput-root {
    color: ${props => props.theme.colors.navy};
    font-size: 1.5rem;

    fieldset {
      border-width: 2px;
    }

    &:hover,
    &:focus {
      fieldset {
        border-color: ${props => props.theme.colors.blue};
      }
    }
  }
`;

const MenuItemStyled = styled(MenuItem)`
  &.MuiListItem-button {
    font-size: 1.5rem;
    padding: 18.5px 14px;
    &.Mui-selected {
      background-color: ${props => props.theme.colors.cloud};
      &:hover {
        background-color: ${props => props.theme.colors.cement};
      }
    }

    &:hover {
      background-color: ${props => props.theme.colors.cloud};
    }
  }
`;

const SelectStyled = styled(Select)`
  .MuiSelect-root {
    font-size: 1.5rem;
  }
`;

const InputSelect = props => {
  const { label, id, options, margin, roiValues, updateRoiValue } = props;
  const [selectVal, setselectVal] = React.useState('');

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);

  const groupReduxName = stringToCamelCase(id);

  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleChange = event => {
    const newRoiValues = { ...roiValues };
    newRoiValues[`${groupReduxName}`] = {
      val: event.target.value,
    };

    setselectVal(event.target.value);
    updateRoiValue(newRoiValues);
  };

  return (
    <FormControlStyled variant="outlined" margin={margin}>
      <InputLabelStyled ref={inputLabel} id={`${id}-label`}>
        {label}
      </InputLabelStyled>
      <SelectStyled
        labelId={`${id}-label`}
        id={id}
        value={selectVal}
        onChange={handleChange}
        labelWidth={labelWidth}
      >
        <MenuItemStyled value="">
          <em>None</em>
        </MenuItemStyled>
        {options &&
          options.length &&
          options.map(option => (
            <MenuItemStyled
              key={option.fields.fieldId}
              value={option.fields.fieldId}
            >
              {option.fields.fieldInput}
            </MenuItemStyled>
          ))}
      </SelectStyled>
    </FormControlStyled>
  );
};

InputSelect.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
};

const mapStateToProps = state => ({
  roiValues: state.roiValues,
});

const mapDispatchToProps = dispatch => ({
  updateRoiValue: values => dispatch(setRoiValues(values)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputSelect);
