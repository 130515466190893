import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { transparentize } from 'polished';
import numeral from 'numeral';
import MarkdownParser from './MarkdownParser';
import Tooltip from './Tooltip/Tooltip';
import { H3 } from './Heading';
import { Paragraph } from './Text';
import { stringToCamelCase } from '../utilities';
import { ReactComponent as Check } from '../svg/check.svg';
import { ReactComponent as DollarSign } from '../svg/dollar.svg';
import config from '../config';

const ResultGroupStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 1.8em 2em;
  margin: 0.6rem auto;
  border-radius: 2rem;
  width: 100%;
  min-height: 8.2rem;
  background-color: ${props =>
    props.automated
      ? transparentize(0.6, props.theme.colors.cloud)
      : transparentize(0.9, props.theme.colors.blue)};

  @media ${props => props.theme.breakpoints.xsmall} {
    flex-flow: column wrap;
    justify-content: center;
  }
`;

const ResultGroupInner = styled.div`
  width: ${props =>
    props.colWidth ? `${(props.colWidth / 12) * 100}%` : '50%'};
  display: flex;
  align-items: center;

  &:first-child {
    margin-right: 1em;
  }

  @media ${props => props.theme.breakpoints.xsmall} {
    width: 100%;
    justify-content: flex-start;

    &:first-child {
      margin: ${props => (props.automated ? '0' : '0 0 1em')};
    }
  }
`;

const ResultGroupInnerMetric = styled(ResultGroupInner)`
  width: ${props =>
    props.colWidth ? `${(props.colWidth / 12) * 100}%` : '50%'};
  justify-content: ${props =>
    props.align === 'right' ? 'flex-end' : 'flex-start'};

  @media ${props => props.theme.breakpoints.xsmall} {
    display: ${props => (props.hideOneMobile ? 'none' : 'flex')};
    margin-left: 4.6em;
  }
`;

const GroupIcon = styled.div`
  color: ${props =>
    props.automated
      ? transparentize(0.3, props.theme.colors.charcoal)
      : props.theme.colors.blue};
  width: 3em;
  margin-right: 1.6em;
`;

const GroupTitle = styled(H3)`
  line-height: 1.6;
  margin: 0;
  color: ${props =>
    props.automated
      ? transparentize(0.3, props.theme.colors.charcoal)
      : props.theme.colors.navy};
  width: calc(100% - 3em);

  @media ${props => props.theme.breakpoints.small} {
    font-size: 1.7em;
  }
`;

const AutomatedLabel = styled(Paragraph)`
  font-size: 1.2em;
  margin-bottom: 0;
  font-weight: 500;
  color: ${props =>
    props.automated
      ? transparentize(0.3, props.theme.colors.charcoal)
      : props.theme.colors.red};
`;

const CheckStyled = styled(Check)`
  color: ${props => props.theme.colors.charcoal};
  margin-right: 1em;
  width: 2em;
  height: 2em;
`;

const DollarSignStyled = styled(DollarSign)`
  color: ${props => props.theme.colors.red};
  margin-right: 1em;
  width: 2em;
  height: 2em;
`;

const ResultSavingsMetric = styled(Paragraph)`
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: right;
  color: ${props => props.theme.colors.blue};

  @media ${props => props.theme.breakpoints.xsmall} {
    text-align: left;
    margin-right: 0;
  }

  @media ${props => props.theme.breakpoints.small} {
    font-size: 1.7em;
  }
`;

const TooltipBlock = styled.div`
  @media ${props => props.theme.breakpoints.xsmall} {
    display: ${props => (props.hideOneMobile ? 'none' : 'block')};
  }
`;

const InputResult = props => {
  const {
    // icon,
    groupIcon,
    inputType,
    inputGroupName,
    hoursSavedPerYear,
    hoursSavedPerYearIfAutomated,
    calculationDescriptionIfAutomated,
    calculationDescription,
  } = props.inputGroup.fields;
  const { val, multiplyPerEmployee } = props.inputResults;
  const [iconSvg, setIconSvg] = useState(null);

  const iconIsSvg =
    groupIcon &&
    groupIcon.fields &&
    groupIcon.fields.file &&
    groupIcon.fields.file.contentType &&
    /svg/.test(groupIcon.fields.file.contentType);

  useEffect(() => {
    if (iconIsSvg) {
      (async () => {
        await fetch(groupIcon.fields.file.url)
          .then(res => res.text())
          .then(body => setIconSvg(body));
      })();
    }
    // eslint-disable-next-line
  }, []);

  /**
   * `automated` = a result item that is either already automated or has a
   * dedicated support team in place
   */
  const automated = val && (val === true || val === 'yes') ? true : false;

  /**
   * Set styles based on whether the user has an automated system, or if there
   * should be a tooltip even with an automated system
   */
  const useAutomatedStyled =
    automated && !!hoursSavedPerYearIfAutomated ? false : automated;

  const multiplier = multiplyPerEmployee ? parseFloat(props.employeeCount) : 1;

  let savingsValueHours = null;

  if (!automated && hoursSavedPerYear && multiplier) {
    savingsValueHours = parseFloat(hoursSavedPerYear) * multiplier;
  } else if (automated && hoursSavedPerYearIfAutomated && multiplier) {
    savingsValueHours = parseFloat(hoursSavedPerYearIfAutomated) * multiplier;
  }

  /**
   * Set the tooltip content depending on whether the result is automated or not
   */
  let tooltipContent = '';
  if (
    automated &&
    hoursSavedPerYearIfAutomated &&
    calculationDescriptionIfAutomated
  ) {
    tooltipContent = calculationDescriptionIfAutomated;
  } else {
    tooltipContent = calculationDescription;
  }

  /**
   * Set the result label value based on the input type
   */
  const inputLabels = {
    positive: 'Automated',
    negative: 'Manual',
  };

  if (stringToCamelCase(inputType) === 'yesNo') {
    inputLabels.positive = 'Yes';
    inputLabels.negative = 'No';
  } else if (stringToCamelCase(inputType) === 'automatedManual') {
    inputLabels.positive = 'Automated';
    inputLabels.negative = 'Manual';
  }

  return (
    <ResultGroupStyled automated={useAutomatedStyled}>
      <ResultGroupInner colWidth={7} automated={useAutomatedStyled}>
        <GroupIcon automated={useAutomatedStyled}>
          {iconSvg && <MarkdownParser>{iconSvg}</MarkdownParser>}
        </GroupIcon>
        <GroupTitle automated={useAutomatedStyled}>{inputGroupName}</GroupTitle>
      </ResultGroupInner>
      <ResultGroupInner colWidth={5} automated={useAutomatedStyled}>
        <ResultGroupInnerMetric align="left" colWidth={6} hideOneMobile>
          {useAutomatedStyled ? <CheckStyled /> : <DollarSignStyled />}
          <AutomatedLabel automated={useAutomatedStyled}>
            {automated ? inputLabels.positive : inputLabels.negative}
          </AutomatedLabel>
        </ResultGroupInnerMetric>
        {!!savingsValueHours && (
          <ResultGroupInnerMetric align="right" colWidth={6}>
            <ResultSavingsMetric>
              Save {numeral(savingsValueHours).format('0,0')} Hours
            </ResultSavingsMetric>
            {tooltipContent && (
              <TooltipBlock hideOneMobile>
                <Tooltip content={tooltipContent} />
              </TooltipBlock>
            )}
          </ResultGroupInnerMetric>
        )}
      </ResultGroupInner>
    </ResultGroupStyled>
  );
};

InputResult.propTypes = {
  fields: PropTypes.shape({
    groupIcon: PropTypes.shape({
      fields: PropTypes.shape({
        file: PropTypes.shape({
          contentType: PropTypes.string,
          url: PropTypes.string,
        }),
      }),
    }),
    inputGroupName: PropTypes.string,
    hoursSavedPerYear: PropTypes.string,
    calculationDescription: PropTypes.string,
  }),
  sys: PropTypes.shape({}),
  inputResults: PropTypes.shape({
    val: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    multiplyPerEmployee: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
  }),
};

InputResult.defaultProps = {
  fields: {},
  sys: {},
  inputResults: {
    val: false,
    multiplyPerEmployee: false,
  },
};

const mapStateToProps = (state, ownProps) => {
  const inputId =
    ownProps &&
    ownProps.inputGroup &&
    ownProps.inputGroup.fields &&
    ownProps.inputGroup.fields.inputId;
  const groupReduxName = inputId && stringToCamelCase(inputId);
  const reduxItem = state.roiValues[`${groupReduxName}`];
  const employeeCount =
    state.roiValues && state.roiValues[config.employeeCountReduxName];
  return {
    inputResults: reduxItem,
    employeeCount: employeeCount && employeeCount.val,
  };
};

export default connect(mapStateToProps)(InputResult);
