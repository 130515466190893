import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { transparentize } from 'polished';
import Switch from './InputSwitch';
import MarkdownParser from './MarkdownParser';
import { H3 } from './Heading';
import Input from './InputText';
import InputRadio from './InputRadio';

const FormGroupStyled = styled.div`
  display: flex;
  align-items: center;
  flex-flow: ${props => (props.textarea ? 'column' : 'row')};
  padding: 1.8em 2em;
  margin: 0.6rem auto;
  border-radius: 2rem;
  width: 100%;
  min-height: 8.2rem;
  transition: ${props => `background-color ${props.theme.transitions.default}`};

  &:nth-child(2n) {
    background-color: ${props =>
      props.textarea
        ? props.theme.colors.white
        : transparentize(0.6, props.theme.colors.cloud)};
  }
  &:nth-child(2n + 1) {
    background-color: ${props =>
      props.textarea ? props.theme.colors.white : props.theme.colors.cloud};
  }

  @media ${props => props.theme.breakpoints.xsmall} {
    flex-flow: column wrap;
  }
`;

const FormLabelGroup = styled.div`
  width: ${props => (props.textarea ? '100%' : '50%')};
  display: flex;
  align-items: center;

  @media ${props => props.theme.breakpoints.medium} {
    ${'' /* width: ${(2 / 3) * 100}%; */}
    ${'' /* width: 50%; */}
    ${'' /* width: ${props => (props.textarea ? '100%' : '50%')}; */}
  }

  @media ${props => props.theme.breakpoints.xsmall} {
    width: 100%;
  }
`;

const FormInputGroup = styled.div`
  width: ${props => (props.textarea ? 'calc(100% - 10em)' : '50%')};
  display: flex;
  align-items: center;
  margin-top: ${props => (props.textarea ? '2em' : '0')};
  @media ${props => props.theme.breakpoints.medium} {
    ${'' /* width: ${(1 / 3) * 100}%; */}
    ${'' /* width: 50%; */}
  }

  @media ${props => props.theme.breakpoints.xsmall} {
    width: 100%;
  }
`;

const GroupIcon = styled.div`
  color: ${props => props.theme.colors.blue};
  min-width: 3em;
  width: 3em;
  margin-right: 2em;

  @media ${props => props.theme.breakpoints.xsmall} {
    ${props =>
      props.noSvg
        ? css`
            min-width: 0;
            width: 0;
            margin-right: 0;
          `
        : ''}
  }
`;

const GroupTitle = styled(H3)`
  line-height: 1.6;
  margin: 0;
`;

const FormGroup = props => {
  const { formGroup, margin } = props;
  const [iconSvg, setIconSvg] = useState(null);

  const {
    // icon,
    groupIcon,
    inputGroupName,
    inputId,
    inputType,
    followupInput,
    hoursSavedPerYear,
    hoursSavedPerYearIfAutomated,
    hoursSavedMultiplier,
  } = formGroup.fields;

  const iconIsSvg =
    groupIcon &&
    groupIcon.fields &&
    groupIcon.fields.file &&
    groupIcon.fields.file.contentType &&
    /svg/.test(groupIcon.fields.file.contentType);

  useEffect(() => {
    if (iconIsSvg) {
      (async () => {
        await fetch(groupIcon.fields.file.url)
          .then(res => res.text())
          .then(body => setIconSvg(body));
      })();
    }
    // eslint-disable-next-line
  }, []);

  const isTextArea = inputType === 'Textarea';

  return (
    <FormGroupStyled textarea={isTextArea}>
      <FormLabelGroup textarea={isTextArea}>
        <GroupIcon noSvg={!iconSvg}>
          {iconSvg && <MarkdownParser>{iconSvg}</MarkdownParser>}
        </GroupIcon>
        {inputGroupName && <GroupTitle>{inputGroupName}</GroupTitle>}
      </FormLabelGroup>
      <FormInputGroup textarea={isTextArea}>
        {inputType && inputType === 'Automated/Manual' ? (
          <Switch
            id={inputId}
            inputGroupName={inputGroupName}
            followupInput={followupInput}
            hoursSavedPerYear={hoursSavedPerYear}
            hoursSavedPerYearIfAutomated={hoursSavedPerYearIfAutomated}
            hoursSavedMultiplier={hoursSavedMultiplier}
            margin={margin}
          />
        ) : inputType && inputType === 'Textarea' ? (
          <Input
            id={inputId}
            multiline
            inputGroupName={inputGroupName}
            margin={margin}
          />
        ) : inputType && inputType === 'Yes/No' ? (
          <InputRadio
            id={inputId}
            questionLabel=""
            hoursSavedPerYear={hoursSavedPerYear}
            hoursSavedPerYearIfAutomated={hoursSavedPerYearIfAutomated}
            hoursSavedMultiplier={hoursSavedMultiplier}
            addLeftMargin={1}
            options={[
              { label: 'Yes', value: 'yes' },
              { label: 'No', value: 'no' },
            ]}
            // handleSelectionState={handleSelectionState}
          />
        ) : null}
      </FormInputGroup>
    </FormGroupStyled>
  );
};

FormGroup.propTypes = {
  formGroup: PropTypes.shape({
    // icon: PropTypes.string,
    inputGroupName: PropTypes.string,
    inputId: PropTypes.string,
    inputType: PropTypes.string,
    groupIcon: PropTypes.shape({
      fields: PropTypes.shape({
        file: PropTypes.shape({
          contentType: PropTypes.string,
          url: PropTypes.string,
        }),
      }),
    }),
    followupInput: PropTypes.shape({
      sys: PropTypes.shape({
        contentType: PropTypes.shape({
          sys: PropTypes.shape({
            id: PropTypes.string,
          }),
        }),
      }),
      fields: PropTypes.shape({
        fieldInput: PropTypes.string,
        fieldId: PropTypes.string,
      }),
    }),
    hoursSavedPerYear: PropTypes.string,
    hoursSavedMultiplier: PropTypes.string,
  }),
  margin: PropTypes.string,
};

FormGroup.defaultProps = {
  formGroup: {
    // icon: null,
    inputGroupName: null,
    inputId: null,
    inputType: null,
    groupIcon: null,
    followupInput: null,
    hoursSavedPerYear: null,
    hoursSavedMultiplier: null,
  },
  margin: 'default',
};

export default FormGroup;
