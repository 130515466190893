/** A place to put miscellaneous config options */
export default {
  shouldUseRemoteContent: true,
  contentful: {
    space: 'dtt3q1pux0yd',
    accessToken: 'ZTwMm3DZ1dFs53UbiXcoNNU_fKWulhmYYwq0VNdZOq4',
  },
  isPaged: true,
  isHeaderUnderFirstBlock: false,
  /** 'dot' or 'full' */
  nav: 'full',
  twitterHandle: '',
  employeeCountReduxName: 'numEmployees',
  pardotEndpointURL:
    'https://contact.payprocorp.com/l/455002/2019-11-19/87mxp8',
  pardotLambdaUrl: '/.netlify/lambdas/pardot',
  pardotLambdaDevUrl: '/lambdas/pardot.js',
};
