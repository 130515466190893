import styled from 'styled-components/macro';
import { transparentize, transitions } from 'polished';

export default styled.button`
  background-color: ${props =>
    props.buttonTheme === 'primary'
      ? props.theme.colors.blue
      : props.theme.colors.white};
  color: ${props =>
    props.buttonTheme === 'primary'
      ? props.theme.colors.white
      : props.theme.colors.blue};
  border: ${props =>
    props.buttonTheme === 'primary'
      ? `2px solid ${props.theme.colors.blue}`
      : `2px solid ${props.theme.colors.white}`};
  text-decoration: none;
  font-size: 1.6rem;
  border-radius: 1rem;
  padding: 1em 2.4rem;
  padding: 1.8rem 5.8rem;
  display: inline-block;
  cursor: pointer;
  font-weight: 600;

  ${props =>
    transitions(
      ['background-color', 'color', 'border-color'],
      props.theme.transitions.default
    )};

  &:hover {
    background-color: ${props => transparentize(1, props.theme.colors.white)};
    color: ${props =>
      props.buttonTheme === 'primary'
        ? props.theme.colors.blue
        : props.theme.colors.white};
    border: ${props =>
      props.buttonTheme === 'primary'
        ? `2px solid ${props.theme.colors.blue}`
        : `2px solid ${props.theme.colors.white}`};
  }
`;
