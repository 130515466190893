/** import React from 'react'; */
/** import Image from './Image'; */
import styled from 'styled-components/macro';
import { ReactComponent as Logo } from '../svg/logo.svg';

/**
 * The logo, which usually just goes in the header. This could easily go inside
 * the Header component, but this keeps it a little easier to read
 */

export default styled(Logo)`
  width: 15em;
  height: auto;
  color: ${props => props.theme.colors.white};
`;

/** SVGs are already compiled as react components, so just do this */
/**
 * export default styled(Logo)`
 *   display: block;
 *   margin: 0 auto;
 *   width: 13em;
 *   max-width: 100%;
 *   height: auto;
 * `;
 */

/** If we wanted to export an actual image we could do this: */
/** export default () => <Image src="" alt="Logo" /> */
