import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import MarkdownParser from './MarkdownParser';
import { connect } from 'react-redux';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${props => props.theme.breakpoints.medium} {
    margin-bottom: 5em;
  }
  @media ${props => props.theme.breakpoints.medium} {
    margin-bottom: 3em;
  }
`;

const IconStyled = styled.div`
  margin: 0 2em;
  position: relative;
  z-index: 2;
  width: 5em;

  &:after {
    position: absolute;
    content: '';
    width: 10em;
    height: 0.2rem;
    background-color: ${props => props.theme.colors.cement};
    z-index: -1;
    left: 150%;
    top: 50%;
    transform: translate(-50%, -200%);
  }

  &:last-child {
    &:after {
      display: none;
    }
  }

  svg {
    background: white;
    padding: 0.4em;
    width: 100%;
    height: auto;

    circle {
      color: ${props =>
        props.isActive ? props.theme.colors.blue : props.theme.colors.cloud};
    }

    #icon {
      color: ${props =>
        props.isActive ? props.theme.colors.white : props.theme.colors.cement};
    }
  }
`;

const IconNav = props => {
  const { icons, currentPage, menuItems } = props;

  const currentPageItem =
    menuItems &&
    menuItems.length &&
    menuItems.filter(page => page.path === currentPage.pathname)[0];

  const currentPageIndex = currentPageItem ? currentPageItem.pageIndex : null;

  return (
    <IconWrapper>
      {icons &&
        icons.length &&
        icons.map((icon, iconIndex) => {
          if (!icon) {
            return false;
          }
          /**
           * Use the icon svg's `title` attr as the key
           */
          const keyTest = /<title>(.*)<\/title>/.exec(icon);
          const key =
            keyTest && keyTest.length
              ? `${keyTest[1].toLowerCase().replace(' ', '-')}-${iconIndex}`
              : `nav-icon-${iconIndex}`;

          /** If the icon is for the current page or a previous page, mark it as 'active' */
          const isActive =
            currentPageIndex !== null ? iconIndex <= currentPageIndex : false;

          return (
            <IconStyled key={key} isActive={isActive}>
              <MarkdownParser>{icon}</MarkdownParser>
            </IconStyled>
          );
        })}
    </IconWrapper>
  );
};

IconNav.propTypes = {
  icons: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = state => ({
  menuItems: state.menu,
});

export default connect(mapStateToProps)(IconNav);
