/** Define common colors, fonts, and other branding here */
const colors = {
  white: '#fff',
  black: '#000',
  blue: '#005dff',
  blueShadow: '#054ad3',
  navy: '#14144b',
  navyShadow: '#080830',
  periwinkle: '#5592ff',
  charcoal: '#495159',
  cloud: '#eaeef4',
  cement: '#c4c8cc',
  red: '#fc0d1b',
};

const fonts = {
  serif: 'serif',
  sansSerif: 'sans-serif',
};

const breakpoints = {
  xsmall: `screen and (max-width: 36em)`,
  small: `screen and (max-width: 48em)`,
  medium: `screen and (max-width: 64em)`,
  large: `screen and (max-width: 96em)`,
  xlarge: `screen and (max-width: 110em)`,
  minHeight: `screen and (max-height: 37em)`,
};

const transitions = {
  default: '0.2s ease-in-out',
};

const theme = {
  colors,
  fonts,
  breakpoints,
  transitions,
};

export default theme;
