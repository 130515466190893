import { getContentfulContent, getLocalContent } from './content';
import { handlePromiseError, createCookie } from '../utilities';

/**
 * Define action creators.
 *
 * These will be used by both the template and the theme
 */

/**
 * Global error notifications, things we'd want the user to see
 * @param {String} err The error message to display
 */
export const globalErrorShow = error => ({
  type: 'GLOBAL_ERROR_SHOW',
  error,
});

export const globalErrorReset = () => ({
  type: 'GLOBAL_ERROR_RESET',
});

/** Set the header either being stuck to the top of the window or not */
export const headerIsStuckSet = bool => ({
  type: 'HEADER_IS_STUCK_SET',
  payload: bool,
});

/** Set whether the header belongs above or below the first section */
export const headerIsUnderFirstSectionSet = bool => ({
  type: 'HEADER_IS_UNDER_FIRST_SECTION_SET',
  payload: bool,
});

/** Action creators used exclusively within async action creators */

const loadContentStart = () => ({
  type: 'LOAD_CONTENT_START',
});

/**
 * Tell the store the content loaded successfully and give it the content
 * @param {Array} data Contains and object for each separate piece of content
 */
const loadContentSuccess = data => ({
  type: 'LOAD_CONTENT_SUCCESS',
  payload: data,
});

const setMenuItems = menu => ({
  type: 'MENU_ITEMS_SET',
  payload: menu,
});

/**
 * Tell the store the content failed to load (for whatever reason), and pass the
 * error to potentially be used
 * @param {String} error An error message
 */
const loadContentError = error => ({
  type: 'LOAD_CONTENT_ERROR',
  payload: error,
});

export const sectionRefsAdd = ref => ({
  type: 'SECTION_REFS_ADD',
  payload: ref,
});

export const setSectionWaypointEnabled = isEnabled => ({
  type: 'SECTION_WAYPOINT_ENABLED_SET',
  payload: isEnabled,
});

export const setGateRelease = isReleased => ({
  type: 'GATE_RELEASE_SET',
  payload: isReleased,
});

/** Handle the gate removal and cookie setting if the gate should be released */
export const disableGate = () => dispatch => {
  dispatch(setGateRelease(true));
  return createCookie('gate-released', 'true', 90);
};

export const initializeRoiValues = menu => ({
  type: 'INIT_ROI_VALUES',
  payload: menu,
});

export const setRoiValues = values => ({
  type: 'ROI_VALUES_SET',
  payload: values,
});

/** Async action creators */

/**
 * Whether you're loading content from Contentful or a local file, the data
 * structure should be the same.
 * @param {Boolean} [shouldUseRemoteContent=true] Use remote or local content
 * @param {Object} remoteContentConfig Contains any config necessary for any
 * given remote content system
 */
export const loadContent = (
  shouldUseRemoteContent = true,
  remoteContentConfig
) => async dispatch => {
  dispatch(loadContentStart());

  /**
   * NOTE: If we ever use something other than contenful for remote content,
   * we'll need to change this check
   */
  const getContent = shouldUseRemoteContent
    ? getContentfulContent
    : getLocalContent;

  try {
    const content = await getContent(remoteContentConfig);
    let menu = [];

    /** Using the loaded content, derive a menu structure */
    if (content.length) {
      menu = content.map((item, index) => {
        return {
          id: item.id,
          path: item.path,
          title: item.menuTitle,
          isGated: item.isGated,
          /** Add a page index to target nav items */
          pageIndex: index,
        };
      });
    }

    /** If no content items are returned this will be an empty array */
    dispatch(loadContentSuccess(content));
    return dispatch(setMenuItems(menu));
  } catch (err) {
    /**
     * Catch both Promse rejections (and errors within those Promises), and any
     * syntax errors in the above try block
     */
    handlePromiseError(err);

    dispatch(loadContentError(err));
    dispatch(
      globalErrorShow(`Something went wrong while loading the content.`)
    );

    // TODO: Is there a better way to delay hiding the error than setTimeout?
    return setTimeout(() => dispatch(globalErrorReset()), 5000);
  }

  // return getContent(remoteContentConfig).then(
  //   data => dispatch(loadContentSuccess(data)),
  //   err => {
  //     dispatch(loadContentError(err));
  //     dispatch(
  //       globalErrorShow(`Something went wrong while loading the content.`)
  //     );
  //     // TODO: Is there a better way to delay hiding the error than setTimeout?
  //     setTimeout(() => {
  //       dispatch(globalErrorReset());
  //     }, 5000);
  //
  //     return false;
  //   }
  // );
};
