/** Define the shape of the app's initial state */
export default {
  /** The app's full content that the user will read */
  content: [],
  /** Menu items for the navigation based on the content */
  menu: [],
  /** Any error notifications we need the user to see */
  globalError: '',
  // contentLoading: false,
  // contentLoaded: false,
  // contentErrored: false,
  header: {
    isUnderFirstBlock: false,
    isStuck: false,
  },
  /** Used for auto-scrolling to different Sections */
  sectionRefs: {},
  isSectionWaypointEnabled: true,
  isGateReleased: false,
  /**
   * ROI Calculator Values
   */
  roiValues: {
    /**
     * The individual field values will be added by the reducer so that they can
     * be added/removed/manipulated through the CMS.
     *
     *
     * hrAutomated: null,
     * payrollAutomated: null,
     * timeSheetsAutomated: null,
     * eeoReportingAutomated: null,
     * acaComplianceAutomated: null,
     * benefitsEnrollmentAutomated: null,
     * trackingLeaveTimeAutomated: null,
     * employeeSelfServiceRequestsAutomated: null,
     */
    // TEMP: for testing:
    // acaComplianceAutomated: {val: null, multiplyPerEmployee: false, hoursSavedPerYear: "80"},
    // benefitsEnrollmentAutomated: {val: true, multiplyPerEmployee: true, hoursSavedPerYear: "3", benefitsEnrollmentCurrentSystem: "Test2"},
    // company: {val: "The Starr Conspiracy"},
    // currentPainPoints: {val: "Very urgent pain points we need addressed asap"},
    // dedicatedImplementationTeam: {val: "yes", multiplyPerEmployee: false, hoursSavedPerYear: "150"},
    // dedicatedSupportTeam: {val: "no", multiplyPerEmployee: false, hoursSavedPerYear: "120"},
    // eeoReportingAutomated: {val: null, multiplyPerEmployee: false, hoursSavedPerYear: "80"},
    // email: {val: "matt.driscoll@thestarrconspiracy.com"},
    // employeeCount: {val: "12"},
    // employeeSelfServiceRequestsAutomated: {val: true, multiplyPerEmployee: true, hoursSavedPerYear: "10"},
    // firstName: {val: "Matt"},
    // hrAutomated: {val: true, multiplyPerEmployee: true, hoursSavedPerYear: "52", hrCurrentSystem: "Test1"},
    // industry: {val: "retail"},
    // lastName: {val: "Driscoll"},
    // payrollAutomated: {val: null, multiplyPerEmployee: false, hoursSavedPerYear: "60"},
    // timeSheetsAutomated: {val: true, multiplyPerEmployee: true, hoursSavedPerYear: "26", timeSheetsCurrentSystem: "Test2"},
    // trackingLeaveTimeAutomated: {val: true, multiplyPerEmployee: true, hoursSavedPerYear: "52"}
  },
};
