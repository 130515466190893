import React from 'react';
import styled from 'styled-components/macro';
import InputResult from './InputResult';

const ResultGroupStyled = styled.form`
  display: flex;
  flex-flow: column nowrap;
`;

const ResultGroup = props => {
  const { inputGroupCollection } = props.fields;

  return (
    <ResultGroupStyled>
      {inputGroupCollection.map(inputGroup => {
        return <InputResult key={inputGroup.sys.id} inputGroup={inputGroup} />;
      })}
    </ResultGroupStyled>
  );
};

export default ResultGroup;
