import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { transparentize } from 'polished';
import { H3 } from './Heading';
import { ReactComponent as CaratIcon } from '../svg/carat.svg';
import DeepDiveQuestion from './DeepDiveQuestion';

const DeepDiveGroupHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em 3em;
  margin: 1.2rem auto;
  border-radius: 2rem;
  width: 100%;
  min-height: 7.4rem;
  cursor: pointer;
  background-color: ${props =>
    props.isOpen
      ? transparentize(0.9, props.theme.colors.blue)
      : transparentize(0.6, props.theme.colors.cloud)};
  transition: ${props => `background-color ${props.theme.transitions.default}`};
`;

const DeepDiveGroupName = styled(H3)`
  line-height: 1.6;
`;

const CaratStyledWrapper = styled.div`
  svg {
    transform: ${props => (props.isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
  }
`;

const CaratStyled = styled(CaratIcon)`
  color: ${props => props.theme.colors.blue};
`;

const DeepDiveGroup = props => {
  const { groupTitle, questionGroup } = props.fields;
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpenQuestion = () => setIsOpen(!isOpen);

  return (
    <>
      <DeepDiveGroupHeader onClick={toggleOpenQuestion} isOpen={isOpen}>
        {groupTitle && <DeepDiveGroupName>{groupTitle}</DeepDiveGroupName>}
        <CaratStyledWrapper isOpen={isOpen}>
          <CaratStyled />
        </CaratStyledWrapper>
      </DeepDiveGroupHeader>
      {questionGroup &&
        questionGroup.length &&
        questionGroup.map(deepDiveQuestion => (
          <DeepDiveQuestion
            key={deepDiveQuestion.sys.id}
            {...deepDiveQuestion}
            isOpen={isOpen}
          />
        ))}
    </>
  );
};

DeepDiveGroup.defaultProps = {
  fields: PropTypes.shape({}),
};

export default DeepDiveGroup;
