export const HUBSPOT_CONFIG = {
  formUrlBase: 'https://api.hsforms.com/submissions/v3/integration/submit',
  portalId: '43860539',
  formId: '484a4c08-fc99-4457-af1e-2b44ed4ada20',
};

export const INPUT_TYPES = {
  basic: 'basicInputGroup',
  group: 'inputGroup',
  deepDiveGroup: 'deepDiveQuestionGroup',
};

export const formatHubspotFormField = (name, value) => ({
  objectTypeId: '0-1',
  name,
  value,
});
