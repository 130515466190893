import React from 'react';
import styled from 'styled-components/macro';
import Input from './InputText';
import Select from './InputSelect';
import { stringToCamelCase } from '../utilities';

const FormGroup = styled.div`
  width: 100%;
`;

export default props => {
  const { formGroup, margin, formErrorFields } = props;

  // console.log({ props })

  const formInputs = formGroup && formGroup.fields && formGroup.fields.inputs;

  return (
    <FormGroup>
      {formInputs &&
        formInputs.map(input => {
          const inputType =
            input.sys &&
            input.sys.contentType &&
            input.sys.contentType.sys &&
            input.sys.contentType.sys.id;

          if (!inputType) {
            return null;
          }

          if (inputType === 'textInput') {
            const inputLabel = input.fields && input.fields.fieldInput;
            const fieldId = input.fields && input.fields.fieldId;

            const isInvalid =
              formErrorFields &&
              fieldId &&
              formErrorFields.indexOf(stringToCamelCase(fieldId)) > -1;

            return (
              <Input
                key={fieldId}
                label={inputLabel}
                id={fieldId}
                type="text"
                margin={margin}
                error={isInvalid}
                customErrorMessage={input.fields.customErrorMessage || null}
              />
            );
          } else if (inputType === 'selectInput') {
            const inputLabel = input.fields && input.fields.fieldInput;
            const fieldId = input.fields && input.fields.fieldId;
            const options = input.fields && input.fields.options;
            return (
              <Select
                key={fieldId}
                label={inputLabel}
                id={fieldId}
                options={options}
                margin={margin}
                customErrorMessage={input.customErrorMessage || null}
              />
            );
          }
          return null;
        })}
    </FormGroup>
  );
};
