import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import numeral from 'numeral';

const CounterStyled = styled.div`
  sup {
    top: -0.6em;
    font-size: 0.5em;
  }
`;

const Value = styled(animated.span)`
  ${'' /* font-size: 7rem;
  margin: 0 0 0.3em;
  line-height: 1.2;
  font-weight: bold;
  color: ${props => props.theme.colors.periwinkle}; */}
`;

/**
 * Count the number of decimal places in a number, even if those decimals are 0.
 *
 * @param {number} value The number to check
 * @returns {number} The number of decimal places, or 0
 */
export const countDecimals = value => {
  const [, decimals] = value.toString().split('.');
  return decimals ? decimals.length : 0;
};

/**
 * Animate a number from one value to the other. Allows for surrounding
 * content and animating decimal places by getting the highest number of decimal
 * places from the starting and ending values
 */
const Counter = props => {
  const [shouldAnimate, setAnimate] = useState(false);
  const springProps = useSpring({
    from: { number: props.start },
    /**
     * There's no "pause" in react-spring currently, so set the start value as
     * the end too so it doesn't actually change
     */
    to: { number: shouldAnimate ? props.end : props.start },
    config: { mass: 1, tension: 100, resistance: 20 },
  });

  /** Get the larger number of decimals from the two values */
  const numberOfDecimals = Math.max(
    countDecimals(props.start),
    countDecimals(props.end)
  );

  return (
    <>
      {/** Don't wrap the Counter in the waypoint bc there's some ref issues */}
      <Waypoint
        topOffset="0"
        bottomOffset="0"
        onEnter={() => (!shouldAnimate ? setAnimate(true) : false)}
      />
      <CounterStyled ref={props.innerRef}>
        {props.prefix === '$' ? <sup>{props.prefix}</sup> : props.prefix}
        <Value>
          {springProps.number.interpolate(value =>
            numeral((+value).toFixed(numberOfDecimals)).format('0,0')
          )}
        </Value>
        {props.suffix}
      </CounterStyled>
    </>
  );
};

Counter.propTypes = {
  /** Values should be strings to avoid some number animation issues */
  start: PropTypes.string,
  end: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
};

Counter.defaultProps = {
  start: '0',
  prefix: '',
  suffix: '',
};

export default Counter;
