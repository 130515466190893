import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setRoiValues } from '../logic/actions';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { TextField, InputLabel } from '@material-ui/core';
import { stringToCamelCase } from '../utilities';

const TextFieldStyled = styled(TextField)`
  background-color: ${props => props.theme.colors.white};
  font-size: 1.5rem;
  ${'' /* border-radius: 1em; */}
  padding: 2.4rem 1.8rem 1.4rem;
  width: 100%;
  font-size: 1.5rem;

  label.MuiFormLabel-root {
    font-size: 1.5rem;
    line-height: 1;
    color: ${props => props.theme.colors.cement};

    /** Label styles when focused */
    &.Mui-focused {
      line-height: 1;
      color: ${props => props.theme.colors.blue};
    }

    /** Label styles when filled */
    &.MuiFormLabel-filled {
      line-height: 1;
      color: ${props => props.theme.colors.navy};
    }
  }

  .MuiOutlinedInput-root {
    color: ${props => props.theme.colors.navy};
    font-size: 1.5rem;

    fieldset {
      border-width: 2px;
    }

    &:hover,
    &:focus {
      fieldset {
        border-color: ${props => props.theme.colors.blue};
      }
    }
  }
`;

const InlineInputGroup = styled.div`
  display: flex;
  align-items: center;
`;

const InlineInputLabelStyled = styled(InputLabel)`
  &.MuiFormLabel-root {
    font-size: 1.4rem;
    color: ${props => props.theme.colors.navy};
    width: ${props => (props.useFullWidth ? '100%' : '33%')};
    max-width: ${props => (props.useFullWidth ? '100%' : '18em')};
    margin-top: 0;
    font-style: ${props => (props.isFollowUp ? 'italic' : 'normal')};
    line-height: 1.5;

    @media ${props => props.theme.breakpoints.medium} {
      width: ${props => (props.useFullWidth ? '100%' : '50%')};
    }
  }
`;

const InlineInputStyled = styled(TextFieldStyled)`
  width: ${props => (props.useFullWidth ? '100%' : '33%')};
  max-width: ${props => (props.useFullWidth ? '13em' : '15em')};
  padding: 1rem;
  &.MuiFormControl-marginDense {
    margin-top: 0;
  }

  @media ${props => props.theme.breakpoints.medium} {
    width: ${props => (props.useFullWidth ? '100%' : '50%')};
  }
`;

const Input = props => {
  const {
    id,
    label,
    required,
    roiValues,
    router,
    error,
    customErrorMessage,
    updateRoiValue,
    inputGroupReduxName,
    margin,
    handleSelectionState,
    useBaseLayout = false,
    useFullWidth = false,
    isFollowUp = false,
    multiline = false,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const groupReduxName = stringToCamelCase(id);

  /** Initialize component and redux state, using url params if available */
  useEffect(() => {
    const newRoiValues = { ...roiValues };
    const urlParams = new URLSearchParams(router.location.search);
    let inputValue = null;

    if (urlParams) {
      const param = urlParams.get(groupReduxName);
      inputValue = param ? param : '';
    }

    /**
     * First check if this is a followup question. If not, set as a top level
     * question
     */
    if (inputGroupReduxName && newRoiValues[inputGroupReduxName]) {
      newRoiValues[inputGroupReduxName][groupReduxName] = inputValue;
    } else {
      newRoiValues[groupReduxName] = { val: inputValue };
    }

    setInputValue(inputValue);
    updateRoiValue(newRoiValues);
    // eslint-disable-next-line
  }, []);

  /** Update redux when input value changes */
  useEffect(() => {
    const newRoiValues = { ...roiValues };

    if (inputGroupReduxName && newRoiValues[inputGroupReduxName]) {
      newRoiValues[inputGroupReduxName][groupReduxName] = inputValue;
    } else {
      newRoiValues[groupReduxName] = { val: inputValue };
    }

    updateRoiValue(newRoiValues);
    // eslint-disable-next-line
  }, [groupReduxName, inputGroupReduxName, inputValue, updateRoiValue]);

  const handleChange = () => e => {
    setInputValue(e.target.value);
    handleSelectionState && handleSelectionState(e);
  };

  const errorMessage = customErrorMessage
    ? customErrorMessage
    : `${label} is required.`;

  // TODO: Build a better check for number inputs
  /**
   * Run a regex check on the error message to determine if the input must be a
   * number. Current check is just to test if the string 'number' occurs in the
   * error message
   */
  const isNumberInput = /number/.test(errorMessage);

  return (
    <>
      {useBaseLayout ? (
        <InlineInputGroup>
          <InlineInputLabelStyled
            htmlFor={id}
            useFullWidth={useFullWidth}
            isFollowUp={isFollowUp}
          >
            {label}
          </InlineInputLabelStyled>
          <InlineInputStyled
            id={id}
            margin="dense"
            variant="outlined"
            onChange={handleChange()}
            value={inputValue}
            error={error}
            helperText={error && errorMessage}
            useFullWidth={useFullWidth}
            isFollowUp={isFollowUp}
            type={isNumberInput ? 'number' : 'text'}
            inputProps={isNumberInput ? { min: '0' } : {}}
          />
        </InlineInputGroup>
      ) : (
        <TextFieldStyled
          id={id}
          label={label}
          margin={margin}
          variant="outlined"
          onChange={handleChange()}
          value={inputValue}
          required={required}
          error={error}
          helperText={error && errorMessage}
          multiline={multiline}
          rows={multiline ? '4' : '1'}
          type={isNumberInput ? 'number' : 'text'}
          inputProps={isNumberInput ? { min: '0' } : {}}
        />
      )}
    </>
  );
};

Input.propTypes = {
  type: PropTypes.oneOf(['text', 'password', 'email', 'number']),
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  handleSelectionState: PropTypes.func,
  required: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
  label: '',
  id: '',
  handleSelectionState: null,
  required: false,
};

const mapStateToProps = state => ({
  roiValues: state.roiValues,
  router: state.router,
});

const mapDispatchToProps = dispatch => ({
  updateRoiValue: values => dispatch(setRoiValues(values)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Input);
