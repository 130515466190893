import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { headerIsStuckSet } from '../logic/actions';

/**
 * Create an empty waypoint to tell the header when to become sticky. This will
 * typically be placed under the first block of each section in a paged
 * lightpaper, and under the first block of only the first section of an inline
 * lightpaper
 */
const HeaderTrigger = props => (
  /**
   * Add a little topOffset so navigating to the exact top of the Section
   * doesn't prevent the waypoint from firing
   */
  <Waypoint topOffset={2} onPositionChange={props.toggleHeaderIsStuck} />
);

HeaderTrigger.propTypes = {
  toggleHeaderIsStuck: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  // toggleHeaderIsStuck: bool => headerIsStuckSet(bool),
  toggleHeaderIsStuck: event =>
    dispatch(
      /** Use the waypoint event to check if the header should become stuck */
      headerIsStuckSet(
        event.currentPosition === 'above' || event.waypointTop === 0
      )
    ),
});

/** Subscribe to redux updates (make it a smart component) */
export default connect(
  null,
  mapDispatchToProps
)(HeaderTrigger);
