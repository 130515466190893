import { createGlobalStyle /**, normalize */ } from 'styled-components/macro';
import { normalize } from 'polished';
import '../config/fontFace.css';

/**
 * This is a one-time-use method to add global styles.
 *
 * Use for things like body and html styles, and anything that else can't fit as
 * a react component
 */
export default createGlobalStyle`
  ${normalize()}

  * {
    box-sizing: border-box;
  }

  html {
    /** Allows us to easily translate px to rem, e.g. 1.8rem = 18px */
    font-size: 62.5%;
    font-family: "Montserrat", Arial, sans-serif;

    @media screen and (max-width: 64em) {
      font-size: 58%;
    }

    @media screen and (max-width: 48em) {
      font-size: 45%;
    }
  }

  body {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;

    /** Show qualified dialogue box on results page */
    #q-messenger-container,
    #q-messenger-frame {
      display: none !important;
      opacity: 0 !important;
      transition: opaticy 0.2ms ease-in-out;
    }

    &.results,
    &.tell-us-more {
      #q-messenger-container,
      #q-messenger-frame {
        display: block !important;
        opacity: 1 !important;
      }
    }
  }

  svg {
    max-width: 100%;
  }
`;
