/**
 * Returns the camelCase version of a string
 *
 * @param  {string} string String to convert to camelCase.
 * @return  {string|boolean} Returns the camelCase version of that string
 */

export default (string = '') =>
  string
    /** Replace any non-word characters with a space */
    .replace(/\W/gi, ' ')
    /** Split the string at any single- or multi-spaced instances */
    .split(/\s+/)
    /** Lowercase all words */
    .map(w => w.toLowerCase())
    /** Join the array back together */
    .join('-');
