import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import copy from 'clipboard-copy';
import styled from 'styled-components/macro';
import { H2 } from './Heading';
// import { CtaButton } from './Links';
import Button from './Button';

const ShareLinkBlock = styled.div`
  background-color: ${props => props.theme.colors.blue};
  border-radius: 2rem;
  margin: 3.2em auto;

  /** Magic number: 6.6em padding to match result items above */
  padding: 3.4em 6.6em;

  @media ${props => props.theme.breakpoints.small} {
    padding: 2em;
    margin-top: 2em;
  }
`;

const ShareHeading = styled(H2)`
  color: ${props => props.theme.colors.white};
`;

const ShareLinkInner = styled.div`
  display: flex;
  align-items: flex-end;

  @media ${props => props.theme.breakpoints.small} {
    flex-flow: column;
    align-items: flex-start;
  }
`;

const ShareLinkWrapper = styled.div`
  background-color: ${props => props.theme.colors.white};
  padding: 0.6em 1em;
  font-size: 1.5em;
  border-radius: 0.7em;
  cursor: default;
  width: 100%;
  margin-right: 2em;
  overflow-x: auto;
  white-space: nowrap;

  @media ${props => props.theme.breakpoints.small} {
    margin: 0 0 1rem;
  }
`;

const CopyButton = styled(Button)`
  padding: 1.3rem 3rem;
  width: 8em;
  font-weight: 600;
  border-radius: 0.7em;

  @media ${props => props.theme.breakpoints.small} {
    width: auto;
  }
`;

const ShareLink = props => {
  const { headingText } = props.fields;
  const { location, roiValues } = props;

  /**
   * Get our collection of quiz results that we will use to build the share url
   */
  const roiQuizResults = Object.keys(roiValues)
    .map(valueName => {
      const resultItem = roiValues[valueName];
      const returnedItem = { name: valueName };
      if (typeof resultItem.val !== 'undefined') {
        returnedItem.val = resultItem.val;
      }
      if (typeof resultItem.val !== 'undefined') {
        returnedItem.val = resultItem.val;
      }
      return returnedItem;
    })
    /**
     * We want to get all quiz results that are either automated or not, as
     * well as the employee count
     */
    .filter(value => typeof value.val !== 'undefined');

  /**
   * Build the share url
   */
  const getShareUrl = (origin, currentLocation, quizValues) => {
    /** Use the current location as the url base */
    let shareString = `${origin}/#${currentLocation.pathname}`;

    /** Initialize share params */
    let shareParams = '';

    /** Loop through quiz answers to build params string */
    quizValues.forEach((item, i) => {
      const paramDelimiter = i === 0 || !shareParams.length ? '?' : '&';
      const val = item.val ? `${item.val}` : '';

      if (typeof val !== 'undefined') {
        // TODO: clean up
        shareParams += `${paramDelimiter}${item.name}=${val}`;
      }
    });

    return encodeURI(`${shareString}${shareParams}`);
  };

  const shareUrl = getShareUrl(
    window.location.origin,
    location,
    roiQuizResults
  );

  const copyString = e => {
    e.preventDefault();
    copy(shareUrl);
  };

  return (
    <ShareLinkBlock>
      {headingText && <ShareHeading>{headingText}</ShareHeading>}
      <ShareLinkInner>
        <ShareLinkWrapper>{shareUrl}</ShareLinkWrapper>
        <CopyButton
          forwardedAs="button"
          onClick={copyString}
          buttonTheme="secondary"
          shareUrl={shareUrl}
        >
          Copy
        </CopyButton>
      </ShareLinkInner>
    </ShareLinkBlock>
  );
};

ShareLink.propTypes = {
  fields: PropTypes.shape({
    headingText: PropTypes.string,
  }),
  sys: PropTypes.shape({
    contentType: PropTypes.shape({
      sys: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  }),
};

ShareLink.defaultProps = {
  fields: {
    headingText: '',
  },
  sys: {},
};

const mapStateToProps = state => {
  return {
    roiValues: state.roiValues,
    location: state.router.location,
  };
};

export default connect(mapStateToProps)(ShareLink);
