import React from 'react';
import styled from 'styled-components/macro';

/* Styles */
const StyledForm = styled.form`
  width: 100%;
`;
const Input = styled.input`
  display: block;
`;

const Submit = styled.button`
  display: block;
`;

/** The Default form used for the Gate */
const GateForm = props => {
  return (
    <>
      <StyledForm onSubmit={props.handleGateDisable}>
        <div>
          <label htmlFor="firstName">First Name</label>
          <Input
            id="firstName"
            name="firstName"
            type="text"
            required
            minlength="1"
          />
        </div>
        <div>
          <label htmlFor="lastName">Last Name</label>
          <Input
            id="lastName"
            name="lastName"
            type="text"
            required
            minlength="1"
          />
        </div>
        <div>
          <label htmlFor="email">Email</label>
          <Input id="email" name="email" type="email" required />
        </div>
        <div>
          <label htmlFor="email">Company</label>
          <Input id="company" name="company" required />
        </div>
        <div>
          <label htmlFor="gdpr">I agree to the use of my data.</label>
          <input type="checkbox" />
        </div>
        <Submit type="submit">Submit</Submit>
      </StyledForm>
    </>
  );
};

export default GateForm;
