import { stripSlashes } from '../../utilities';

/**
 * Simplify the nested reference-collection fields.
 *
 * This only handles one level of the collections references.
 * If those references contain collections then they can still be
 * used elsewhere but won't have the simplified object structure.
 */
export default (item, index) => {
  const collection = [];
  /** Check for any actual item data */
  if (item.fields) {
    /** Check for a collection of blocks in each item */
    if (item.fields.collection) {
      /** Format each collection for the item to use */
      item.fields.collection.map(col => {
        /**
         * When a content item is unpublished it won't have
         * createdAt or updatedAt keys. Check for it to prevent
         * throwing an error when trying to load unpublished
         * content
         */
        if (col.sys.createdAt) {
          return collection.push({
            fields: col.fields,
            contentType: col.sys.contentType.sys.id,
            id: col.sys.id,
          });
        }
        return false;
      });
    }

    const path =
      index === 0
        ? '/'
        : item.fields.path
        ? `/${stripSlashes(item.fields.path)}`
        : `/${item.sys.id}`;

    /**
     * Create a simpler content object for the content puller
     * to read for the menu and content. Also allow custom fields
     * to be added by being lenient on the fields object
     */
    return {
      ...item.fields,
      contentType: item.sys.contentType.sys.id,
      id: item.sys.id,
      /** Override the cover page to make it the root */
      path,
      /** Override the default collection */
      collection,
    };
  }
};
