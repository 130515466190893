import React from 'react';
import MarkdownParser from './MarkdownParser';

const TextBlock = props => {
  const { fields } = props;

  return (
    fields &&
    fields.textBlockContent && (
      <MarkdownParser>{fields.textBlockContent}</MarkdownParser>
    )
  );
};

export default TextBlock;
