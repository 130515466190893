import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import Container from '../Container';
import { H1 } from '../Heading';
import { CtaButton } from '../Links';
import MarkdownParser from '../MarkdownParser';
import { Intro } from '../Text';

const HeroContainer = styled(Container)`
  margin: ${props => (props.isEmptyHeader ? '8em auto' : '18em auto')};
  display: flex;
  justify-content: space-between;

  @media ${props => props.theme.breakpoints.medium} {
    padding: 0 6em;
  }

  @media ${props => props.theme.breakpoints.small} {
    margin: ${props => (props.isEmptyHeader ? '8em auto' : '14em auto')};
    flex-flow: column-reverse;
    align-items: center;
    padding: 0 3em;
  }
`;

export const HeroStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  height: ${props => (props.isEmptyHeader ? null : 'calc(100vh - 5em)')};
  min-height: ${props => (props.isEmptyHeader ? null : '60em')};
  max-height: ${props => (props.isEmptyHeader ? null : '74em')};
  background-image: ${props =>
    props.backgroundImage
      ? `url(${props.backgroundImage.fields.file.url})`
      : null};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  /** Assumes the color name option in contentful matches a color name in theme.js */
  background-color: ${props =>
    props.backgroundColor
      ? props.theme.colors[props.backgroundColor.toLowerCase()]
      : null};

  color: ${props =>
    props.backgroundColor.toLowerCase() === 'white'
      ? props.theme.colors.charcoal
      : props.theme.colors.white};

  @media ${props => props.theme.breakpoints.small} {
    height: auto;
    min-height: auto;
    max-height: initial;
  }
`;

const HeaderContent = styled.div`
  width: ${props => (props.isHalfWidth ? '60%' : '100%')};

  @media ${props => props.theme.breakpoints.small} {
    width: 100%;
  }
`;

const HeaderHeroImage = styled.div`
  width: 30%;

  @media ${props => props.theme.breakpoints.small} {
    max-width: 20em;
    text-align: center;
    width: 100%;

    svg {
      height: auto;
    }
  }
`;

const HeroButton = styled(CtaButton)`
  font-weight: 600;
`;

const Hero = ({ fields }) => {
  const {
    title,
    body,
    backgroundImage,
    backgroundColor,
    ctaButton,
    heroImageSvg,
  } = fields;

  const isEmptyHeader = !title && !body && !ctaButton && !heroImageSvg;

  return (
    <HeroStyled
      backgroundImage={backgroundImage || null}
      backgroundColor={backgroundColor ? backgroundColor.toLowerCase() : null}
      isEmptyHeader={isEmptyHeader}
    >
      <HeroContainer isEmptyHeader={isEmptyHeader}>
        <HeaderContent isHalfWidth={!!heroImageSvg}>
          <H1>{title}</H1>
          {body && body.length && <Intro>{body}</Intro>}
          {ctaButton && (
            <HeroButton
              href={ctaButton.fields.buttonHref}
              buttonTheme={ctaButton.fields.buttonTheme}
            >
              {ctaButton.fields.buttonText}
            </HeroButton>
          )}
        </HeaderContent>
        {heroImageSvg && (
          <HeaderHeroImage>
            <MarkdownParser>{heroImageSvg}</MarkdownParser>
          </HeaderHeroImage>
        )}
      </HeroContainer>
    </HeroStyled>
  );
};

Hero.propTypes = {
  fields: PropTypes.shape({
    /** Body is the only required child of fields */
    body: PropTypes.string,
    backgroundImage: PropTypes.shape({
      fields: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }),
  }).isRequired,
};

export default Hero;
