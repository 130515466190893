/**
 * Returns the camelCase version of a string
 *
 * @param  {string} string String to convert to camelCase.
 * @return  {string|boolean} Returns the camelCase version of that string
 */

export default (string = '') =>
  string
    /** Replace any non-word characters with a space */
    .replace(/\W/gi, ' ')
    /** Split the string at any single- or multi-spaced instances */
    .split(/\s+/)
    /** The first word should be lowercased, and all subsequent words should be capitalized */
    // .map((w, i) =>
    //   i === 0
    //     ? w.toLowerCase()
    //     : w[0].toUpperCase() + w.substring(1).toLowerCase())
    .map((w, i) =>
      i === 0 && w.length
        ? w[0].toLowerCase() + w.substring(1)
        : w.length
        ? w[0].toUpperCase() + w.substring(1)
        : ''
    )
    /** Join the array back together */
    .join('');
