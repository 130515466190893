import { push } from 'connected-react-router';
// import qs from 'query-string';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import config from '../config';
import {
  INPUT_TYPES,
  HUBSPOT_CONFIG,
  formatHubspotFormField,
} from '../config/forms.js';
import { stringToCamelCase } from '../utilities';
import Button from './Button';
import DeepDiveQuestionCollection from './DeepDiveQuestionCollection';
import FormGroup from './FormGroup';
import FormGroupBasic from './FormGroupBasic';
import { Paragraph } from './Text';

const FormWrapper = styled.div`
  background-color: transparent;
  box-shadow: none;
  color: inherit;
  font-size: 1em;
  line-height: inherit;
  overflow: initial;
  padding: 0;
  position: static;
`;

const FormStyled = styled.form`
  display: flex;
  flex-flow: column nowrap;
  margin: ${props => `${props.margin} auto`};

  .MuiFormHelperText-contained {
    font-size: 0.9em;
  }
`;

const FormInner = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: ${props => `${(props.colWidth / 12) * 100}%`};
  margin: 0 auto;

  @media ${props => props.theme.breakpoints.xsmall} {
    width: 100%;
  }
`;

const SubmitButton = styled(Button)`
  margin: 1.4em auto;
`;

/** Convert an object of form values to x-www-urlencoded (OR JSON!!!) */
const getFormData = (fieldsRaw, roiValuesState, groupContentType) => {
  let formEncoded = '';
  let inputGroupValues = {};

  /** JSON Updates */
  const formJSON = {};
  const formFieldsArr = [];

  for (let field of fieldsRaw) {
    if (groupContentType === INPUT_TYPES.basic) {
      const fieldId =
        field.fields &&
        field.fields.fieldId &&
        `${stringToCamelCase(field.fields.fieldId)}`;

      const fieldValue = roiValuesState[fieldId] && roiValuesState[fieldId].val;

      if (fieldId && fieldValue) {
        formEncoded += `${formEncoded.length ? '&' : ''}${encodeURIComponent(
          fieldId
        )}=${encodeURIComponent(fieldValue)}`;

        formJSON[fieldId] = fieldValue;
        formFieldsArr.push(formatHubspotFormField(fieldId, fieldValue));
      }
    } else if (groupContentType === INPUT_TYPES.group) {
      /**
       * For inputGroup, first check if the field has
       * `sendFormFieldIndividually` set to true. If so, separate that out in
       * `formEncoded`. Otherwise, group inputs together under the group name
       * and group together as one input value.
       */
      if (field.fields.sendFormFieldIndividually) {
        const fieldId =
          field.fields &&
          field.fields.inputId &&
          `${stringToCamelCase(field.fields.inputId)}`;

        const fieldValue =
          roiValuesState[fieldId] && roiValuesState[fieldId].val;

        if (fieldId && fieldValue) {
          formEncoded += `${formEncoded.length ? '&' : ''}${encodeURIComponent(
            fieldId
          )}=${encodeURIComponent(fieldValue)}`;

          formJSON[field.fields.inputId] = fieldValue;
          formFieldsArr.push(
            formatHubspotFormField(field.fields.inputId, fieldValue)
          );
        }
      } else if (field.parentInput) {
        /** If this is a followup input */
        const fieldName = `${field.parentInput} - ${field.fields.fieldInput}`;
        // const fieldParentName = field.fields && field.parentInput;
        const parentReduxName = stringToCamelCase(field.parentId);
        const fieldReduxName = stringToCamelCase(field.fields.fieldId);
        const fieldValue =
          roiValuesState[parentReduxName] &&
          roiValuesState[parentReduxName][fieldReduxName];

        if (fieldName && fieldValue) {
          if (!inputGroupValues[field.formId]) {
            inputGroupValues[field.formId] = {};
          }
          inputGroupValues[field.formId][fieldName] = fieldValue;

          formJSON[field.fields.fieldId] = fieldValue;
          formFieldsArr.push(
            formatHubspotFormField(field.fields.fieldId, fieldValue)
          );
        }
      } else {
        /** If this is a standard input item (ie. manual/automated) */
        const fieldIdinputId = field.fields && field.fields.inputId;
        const fieldName = field.fields && field.fields.inputGroupName;
        const fieldReduxName = stringToCamelCase(fieldIdinputId);
        const fieldValue =
          roiValuesState[fieldReduxName] &&
          roiValuesState[fieldReduxName].val &&
          (roiValuesState[fieldReduxName].val.toString() === 'true' ||
            roiValuesState[fieldReduxName].val === 'yes')
            ? true //'Automated'
            : roiValuesState[fieldReduxName] &&
              roiValuesState[fieldReduxName].val &&
              roiValuesState[fieldReduxName].val !== 'no'
            ? roiValuesState[fieldReduxName].val
            : false; // 'no';

        if (fieldName && fieldValue) {
          if (!inputGroupValues[field.formId]) {
            inputGroupValues[field.formId] = {};
          }
          inputGroupValues[field.formId][fieldName] = fieldValue;

          formJSON[fieldIdinputId] = fieldValue;
          formFieldsArr.push(
            formatHubspotFormField(fieldIdinputId, fieldValue)
          );
        }
      }
    } else if (groupContentType === INPUT_TYPES.deepDiveGroup) {
      /** Build out form input value for deep dive questions */
      for (let field of fieldsRaw) {
        const fieldId = field.fields.questionId
          ? field.fields.questionId
          : field.fields.fieldId;

        const fieldReduxName = field.fields.questionId
          ? stringToCamelCase(field.fields.questionId)
          : field.fields.fieldId
          ? stringToCamelCase(field.fields.fieldId)
          : null;
        const fieldName =
          field.parentGroupTitle && field.fields.questionLabel
            ? `${field.parentGroupTitle} - ${field.fields.questionLabel}`
            : field.parentGroupTitle && field.fields.fieldInput
            ? `${field.parentGroupTitle} - ${field.fields.fieldInput}`
            : null;
        const fieldValue =
          roiValuesState[fieldReduxName] && roiValuesState[fieldReduxName].val
            ? roiValuesState[fieldReduxName].val
            : null;

        if (fieldName && fieldValue) {
          if (!inputGroupValues[field.formId]) {
            inputGroupValues[field.formId] = {};
          }
          inputGroupValues[field.formId][fieldName] = fieldValue;

          formJSON[fieldId] = fieldValue;

          if (!formFieldsArr.some(f => f.name === fieldId)) {
            formFieldsArr.push(formatHubspotFormField(fieldId, fieldValue));
          }
        }
      }
    }
  }

  const inputGroupNamesArr = Object.keys(inputGroupValues);

  for (let groupName of inputGroupNamesArr) {
    const inputValues = inputGroupValues[groupName];

    let groupVals = `${formEncoded.length ? '&' : ''}${groupName}=`;
    const inputNamesArr = Object.keys(inputValues);
    for (let inputName of inputNamesArr) {
      const inputVal = inputValues[inputName];

      if (inputName && inputVal) {
        groupVals += encodeURIComponent(`${inputName}: ${inputVal}\n`);
      }
    }

    formEncoded += groupVals;
  }

  /** Add email field if hasn't been included yet so Pardot can link the user */
  if (
    !/email=/.test(formEncoded) &&
    roiValuesState.email &&
    roiValuesState.email.val
  ) {
    formEncoded += `${formEncoded.length ? '&' : ''}email=${encodeURIComponent(
      roiValuesState.email.val
    )}`;
    // formJSON.email = roiValuesState.email.val;
  }
  if (!formFieldsArr.some(f => f.name === 'email')) {
    formFieldsArr.push(
      formatHubspotFormField('email', roiValuesState.email.val)
    );
  }

  return { formEncoded, formJSON, formFieldsArr };
};

const Form = props => {
  const { formElement, roiValues } = props;
  const {
    formId,
    inputGroups,
    redirectPage,
    formWidth,
    thankYouMessage,
  } = formElement.fields;

  const [formErrorFields, setFormErrorFields] = useState([]);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false);

  /**
   * `formWidth` is preset in contentful in the following format: /\d Column/
   */
  const colWidth = formWidth ? parseFloat(formWidth.replace(' Column', '')) : 9;

  /** Set the group input type so we know how to format that results */

  let groupContentType = INPUT_TYPES.group;

  /** Get all fields */
  const formFields = inputGroups.reduce((acc, currentGroup) => {
    const contentType =
      currentGroup &&
      currentGroup.sys &&
      currentGroup.sys.contentType &&
      currentGroup.sys.contentType.sys &&
      currentGroup.sys.contentType.sys.id;
    let inputs = [];

    if (contentType === INPUT_TYPES.basic) {
      /** A basic input group only has top level inputs */
      inputs = currentGroup.fields && currentGroup.fields.inputs;
      groupContentType = contentType;
    } else if (contentType === INPUT_TYPES.group) {
      /**
       * A questionnaire inputGroup has top level inputs and optional followup
       * inputs.
       */
      groupContentType = contentType;

      inputs = [{ sys: currentGroup.sys, fields: currentGroup.fields, formId }];
      if (currentGroup.fields.followupInput) {
        /** inputGroup inputs */
        currentGroup.fields.followupInput.parentInput =
          currentGroup.fields.inputGroupName;
        currentGroup.fields.followupInput.parentId =
          currentGroup.fields.inputId;
        currentGroup.fields.followupInput.formId = formId;
        inputs.push(currentGroup.fields.followupInput);
      }
    } else if (contentType === INPUT_TYPES.deepDiveGroup) {
      /**
       * A deepDiveQuestionGroup has top level inputs and optional followup
       * inputs.
       */
      groupContentType = contentType;

      const questionsGroups =
        currentGroup &&
        currentGroup.fields &&
        currentGroup.fields.questionGroup;

      const parentGroupTitle = currentGroup.fields.groupTitle;

      for (let group of questionsGroups) {
        group.parentGroupTitle = parentGroupTitle;
        group.formId = formId;

        inputs.push(group);

        if (
          group.fields.followupQuestions &&
          group.fields.followupQuestions.length
        ) {
          for (let followupQuestion of group.fields.followupQuestions) {
            followupQuestion.parentGroupTitle = parentGroupTitle;
            followupQuestion.formId = formId;

            inputs.push(followupQuestion);
          }
        }
      }
    }

    return inputs && inputs.length ? [...acc, ...inputs] : acc;
  }, []);

  /** Get required fields */
  const requiredFields = formFields.reduce((acc, currentField) => {
    return typeof currentField.fields.required !== 'undefined' &&
      currentField.fields.required
      ? [...acc, currentField.fields.fieldId]
      : acc;
  }, []);

  /**
   * Checks form values to make sure required fields are not empty
   */
  const validateForm = (requiredFieldNames = [], roiValuesState) => {
    return requiredFieldNames.every(
      fieldName => roiValuesState[`${stringToCamelCase(fieldName)}`].val.length
    );
  };

  /**
   * Returns a list of required field names that are invalid
   */
  const getFormErrorFields = (requiredFieldNames, roiValuesState) =>
    requiredFieldNames.filter(fieldName => {
      const roiVal = roiValuesState[`${fieldName}`].val;
      let isValid = false;

      if (fieldName === config.employeeCountReduxName) {
        /** employeeCount is required and must be a positive number */
        if (
          !roiVal ||
          !roiVal.length ||
          parseFloat(roiVal) === 'NaN' ||
          parseFloat(roiVal) <= 0
        ) {
          isValid = false;
        }
      } else if (!roiVal || !roiVal.length) {
        /** We want to capture the required inputs that do not have a value */
        isValid = false;
      } else {
        isValid = true;
      }

      return !isValid;
    });

  const submitForm = () => {
    const { formFieldsArr } = getFormData(
      formFields,
      roiValues,
      groupContentType
    );

    const { formUrlBase, portalId, formId } = HUBSPOT_CONFIG;

    try {
      // const res = fetch(
      //   `https://roi.payprocorp.com/.netlify/functions/forward-request`,
      //   {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify({
      //       fields: formFieldsArr,
      //     }),
      //   }
      // );

      fetch(`${formUrlBase}/${portalId}/${formId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          fields: formFieldsArr,
        }),
      });
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    const formIsValid = validateForm(requiredFields, roiValues);

    if (formIsValid) {
      /**
       * If we have a redirect url, push that url to the router
       */
      if (redirectPage) {
        try {
          submitForm();
          props.push(redirectPage);
        } catch (e) {
          /** TODO: What happens if this fails? */
          console.error(e);
        }
      } else {
        /**
         * If not, show a thank you message
         */
        try {
          submitForm();
        } catch (e) {
          /** TODO: What happens if this fails? */
          console.error(e);
        }
        setShowThankYouMessage(true);
      }
    } else {
      // TODO: set input error messages
      const invalidFieldNames = getFormErrorFields(requiredFields, roiValues);

      setFormErrorFields(invalidFieldNames);
    }
  };

  /**
   * Add some space to the last page
   */
  const isDeepDivePage =
    formElement.fields && formElement.fields.formName === 'Deep Dive Questions';
  const margin = isDeepDivePage ? '5em' : '0';

  const EmailField = () => {
    const inputs = [
      {
        fields: {
          fieldId: 'email',
          fieldInput: 'Email',
          required: true,
        },
        sys: {
          contentType: {
            sys: {
              id: 'textInput',
              linkType: 'ContentType',
              type: 'Link',
            },
          },
        },
      },
    ];

    return (
      <FormGroupBasic
        key="deep-dive-email"
        formGroup={{
          fields: {
            inputs,
          },
        }}
      />
    );
  };

  return (
    <FormWrapper>
      <FormStyled margin={margin}>
        <FormInner colWidth={colWidth}>
          {/**
           * Pardot will not work if we do not send the user's email. Include
           * email if we don't already have it .
           */}
          {isDeepDivePage &&
          (!roiValues.email ||
            !roiValues.email.val ||
            !roiValues.email.val.length) ? (
            <EmailField />
          ) : null}

          {inputGroups &&
            inputGroups.map((formGroup, i) => {
              const groupType =
                formGroup.sys &&
                formGroup.sys.contentType &&
                formGroup.sys.contentType.sys &&
                formGroup.sys.contentType.sys.id;

              return groupType === INPUT_TYPES.basic ? (
                <FormGroupBasic
                  key={formGroup.sys.id}
                  formGroup={formGroup}
                  margin="normal"
                  formErrorFields={formErrorFields}
                />
              ) : groupType === INPUT_TYPES.group ? (
                <FormGroup
                  key={formGroup.sys.id}
                  formGroup={formGroup}
                  margin="dense"
                />
              ) : groupType === INPUT_TYPES.deepDiveGroup ? (
                <>
                  <DeepDiveQuestionCollection
                    {...formGroup}
                    key={formGroup.sys.id}
                  />
                </>
              ) : null;
            })}
          {showThankYouMessage && thankYouMessage ? (
            <Paragraph>{thankYouMessage}</Paragraph>
          ) : (
            <SubmitButton onClick={handleFormSubmit} buttonTheme="primary">
              Submit
            </SubmitButton>
          )}
        </FormInner>
      </FormStyled>
    </FormWrapper>
  );
};

const mapStateToProps = state => ({
  roiValues: state.roiValues,
});

export default connect(
  mapStateToProps,
  { push }
)(Form);
